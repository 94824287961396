import {
  calendarRouterConfig,
  dashboardsRouterConfig,
  financeRouterConfig,
  helpRouterConfig,
  homeRouterConfig,
  modeReportsDashboardsRouterConfig,
  patientSourceRouterConfig,
  preferencesRouterConfig,
  preScreenRouterConfig,
  resetPasswordRouterConfig,
  setupRouterConfig,
  studyRouterConfig
} from '../../pages';

import { generateRouterConfigMap, generateRoutes, prepareConfig, validateConfig } from './service';

const routerConfig = [
  homeRouterConfig,
  helpRouterConfig,
  resetPasswordRouterConfig,
  ...setupRouterConfig,
  ...patientSourceRouterConfig,
  ...studyRouterConfig,
  ...financeRouterConfig,
  calendarRouterConfig,
  ...preScreenRouterConfig,
  preferencesRouterConfig,
  dashboardsRouterConfig,
  modeReportsDashboardsRouterConfig
];

/** This config contains routes with absolute path, key and some additional information **/
const preparedRouterConfig = prepareConfig(validateConfig(routerConfig));

/** It is helper map which provides fast access to each route by a route key **/
export const preparedRouterConfigMap = generateRouterConfigMap(preparedRouterConfig);

/** It is generated route components based on config **/
export const appRoutes = generateRoutes(preparedRouterConfig);
