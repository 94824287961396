import axios from 'axios';

const ROOT = 'api/v1/outreach-attempts';

const OutreachContactAttemptApi = {
  getAttempts(ssuPatientId) {
    return axios.get(`${ROOT}/${ssuPatientId}`, { hideLoader: true });
  },
  saveAttempts(ssuPatientId, attempts) {
    return axios.put(`${ROOT}/${ssuPatientId}/save`, attempts);
  }
};

export default OutreachContactAttemptApi;
