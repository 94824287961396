import Amplify from 'aws-amplify';
import { memoize } from 'lodash/function';

import separateAxiosInstance from '../api/separateAxiosInstance';
import { onRequestError } from '../services/handlers';

function configure(config) {
  Amplify.configure({
    Auth: config.amplifyConfig
  });
  return config.returnedConfig;
}

async function cognitoConfig() {
  return Promise.all([
    separateAxiosInstance('/actuator/env/com.ixortalk.security.jwt.aws.userPoolId').then(resp => resp.data),
    separateAxiosInstance('actuator/env/com.ixortalk.security.jwt.aws.webClientId').then(resp => resp.data),
    separateAxiosInstance('/actuator/env/com.ixortalk.security.jwt.aws.region').then(resp => resp.data),
    separateAxiosInstance('/actuator/env/com.ixortalk.security.jwt.aws.signatureWebClientId').then(resp => resp.data)
  ])
    .then(responses => {
      const userPoolId = responses[0].property.value;
      const userPoolWebClientId = responses[1].property.value;
      const region = responses[2].property.value;
      const userPoolSignatureWebClientId = responses[3].property.value;
      // data structure used by Amplify for main app authentication
      const amplifyConfig = {
        userPoolId,
        userPoolWebClientId,
        region,
        mandatorySignIn: true
      };
      // data structure returned to the rest of the application.
      const returnedConfig = {
        userPoolId,
        userPoolWebClientId,
        region,
        userPoolSignatureWebClientId
      };
      return {
        amplifyConfig,
        returnedConfig
      };
    })
    .then(configure)
    .catch(err => onRequestError(err, { customMessage: 'Could not reach server for auth info' }));
}

export default memoize(cognitoConfig);
