export function mapStudySite(ssu) {
  return {
    uniqueIdentifier: ssu.id,
    siteStatus: ssu.siteStatus,
    studyIdentifier: ssu.study.id,
    siteIdentifier: ssu.site.id,
    studySiteStartups: ssu.studySiteStartups,
    consentExpectedSiv: ssu.consentExpectedSiv,
    expectedCompletionSiv: ssu.expectedCompletionSiv,
    expectedEnrollmentSiv: ssu.expectedEnrollmentSiv,
    consentExpectedPsv: ssu.consentExpectedPsv,
    expectedCompletionPsv: ssu.expectedCompletionPsv,
    expectedEnrollmentPsv: ssu.expectedEnrollmentPsv,
    study: {
      uniqueIdentifier: ssu.study.id,
      studyName: ssu.study.name,
      projectCode: ssu.study.projectCode,
      therapeuticArea: ssu.study.therapeuticArea,
      nonTraditional: ssu.study.nonTraditional
    },
    site: {
      uniqueIdentifier: ssu.site.id,
      siteName: ssu.site.name
    }
  };
}
