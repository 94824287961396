import React from 'react';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import ScheduleAppointmentModal from '../../patient-source/Patients/PatientInfo/EncountersSection/ScheduleAppointmentModal/ScheduleAppointmentModal';

export default function ScheduleAppointment({ sitePatientId, studyId, encounterId, patientIdentifier }) {
  function onShowScheduleEncounter() {
    ModalBoxes.open({
      component: (
        <ScheduleAppointmentModal
          patientId={patientIdentifier}
          ssuPatientId={sitePatientId}
          studyId={studyId}
          isWorklistPage
          encounterId={encounterId}
        />
      )
    });
  }

  return <ControlPointIcon onClick={onShowScheduleEncounter} className="material-icons-blue" />;
}
