import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

import { APPROVE_SITE_PAYMENTS, EXPORT_SITE_PAYMENT_NS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { useCurrentUser } from '../../../../../root/Container/CurrentUserContainer';
import { SitePaymentsContext } from '../../SitePaymentsContext';
import {
  openCreateBillModal,
  openMarkAsPaidModal,
  openRejectModal,
  prepareItemsForReadyForApproval
} from '../SelectedItemsMenu/SelectedItemsMenu';

export const SitePaymentsTableItemMenu = ({ row }) => {
  const { onResetToOpen, applyFilter, onApprove, exportNS, tableData } = useContext(SitePaymentsContext);
  const currentUser = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const readyForApprovalEnabled = useMemo(
    () => ['OPEN', 'REJECTED'].includes(row.siteApprovalStatus) && row?.sitePaymentBillNumber,
    [row.siteApprovalStatus, row.sitePaymentBillNumber]
  );

  const resetToOpenEnabled = useMemo(() => ['READY_FOR_APPROVAL', 'REJECTED'].includes(row.siteApprovalStatus), [
    row.siteApprovalStatus
  ]);

  const createSiteBillEnabled = useMemo(() => {
    const withoutParentAndNoSiteBill = row.withoutParent && !row.sitePaymentBillNumber;
    const parentPaymentTypeAndNoParentBill = row.sitePaymentType === 'PARENT' && !row.parentSiteBillNumber;
    const eventHasNoSiteBillNumber = !row.sitePaymentBillNumber;
    const eventsHaveSiteApprovalStatusOpen = ['OPEN'].includes(row.siteApprovalStatus);
    const everyEventHasParentWithBillNumber = row.parentSiteBillNumber;

    return (
      withoutParentAndNoSiteBill ||
      parentPaymentTypeAndNoParentBill ||
      (eventHasNoSiteBillNumber && eventsHaveSiteApprovalStatusOpen && everyEventHasParentWithBillNumber)
    );
  }, [
    row.withoutParent,
    row.sitePaymentType,
    row.parentSiteBillNumber,
    row.sitePaymentBillNumber,
    row.siteApprovalStatus
  ]);

  const approveEnabled = useMemo(() => {
    const eventWasMarkedAsReadyForApprovalNotByCurrentUser = row.readyForApprovalBy !== currentUser.personnelIdentifier;
    const eventHaveSiteApprovalStatusReadyForApproval = ['READY_FOR_APPROVAL'].includes(row.siteApprovalStatus);

    return eventWasMarkedAsReadyForApprovalNotByCurrentUser && eventHaveSiteApprovalStatusReadyForApproval;
  }, [currentUser.personnelIdentifier, row.readyForApprovalBy, row.siteApprovalStatus]);

  const rejectEnabled = useMemo(() => {
    const sitePaymentStatusIsNotPaid = !['PAID'].includes(row.sitePaymentStatus);
    return ['READY_FOR_APPROVAL', 'APPROVED'].includes(row.siteApprovalStatus) && sitePaymentStatusIsNotPaid;
  }, [row.siteApprovalStatus, row.sitePaymentStatus]);

  const markAsPaidEnabled = useMemo(() => {
    const eventHasSiteApprovalStatusApproved = ['APPROVED'].includes(row.siteApprovalStatus);
    const eventHasSitePaymentStatusPending = ['PENDING'].includes(row.sitePaymentStatus);
    return eventHasSiteApprovalStatusApproved && eventHasSitePaymentStatusPending;
  }, [row.siteApprovalStatus, row.sitePaymentStatus]);

  const exportNSEnabled = useMemo(() => {
    const eventHasBillNumber = row.sitePaymentBillNumber;
    const onlyOneEventWithThisBillNumberExists =
      tableData.filter(({ sitePaymentBillNumber }) => sitePaymentBillNumber === row.sitePaymentBillNumber).length === 1;
    return eventHasBillNumber && onlyOneEventWithThisBillNumberExists;
  }, [row.sitePaymentBillNumber, tableData]);

  return (
    <>
      <MoreVertIcon onClick={openMenu} data-testid={`event-row-menu-${row.eventNumber}.${row.adjustmentSequence}`} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              width: '170px'
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={() => {
            openCreateBillModal([row], applyFilter);
            handleCloseMenu();
          }}
          disabled={!createSiteBillEnabled}
        >
          Create Bill
        </MenuItem>
        {userHasAccessTo(EXPORT_SITE_PAYMENT_NS) && (
          <MenuItem
            onClick={() => {
              exportNS([row]);
              handleCloseMenu();
            }}
            disabled={!exportNSEnabled}
          >
            Export NS
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            navigate('/site-payments/ready-for-approval', {
              state: { checkedEvents: prepareItemsForReadyForApproval([row]) }
            });
            handleCloseMenu();
          }}
          disabled={!readyForApprovalEnabled}
        >
          Ready for Approval
        </MenuItem>
        <MenuItem
          onClick={() => {
            onResetToOpen([row.itemSiteId]);
            handleCloseMenu();
          }}
          disabled={!resetToOpenEnabled}
        >
          Reset to Open
        </MenuItem>
        {userHasAccessTo(APPROVE_SITE_PAYMENTS) && (
          <MenuItem
            onClick={() => {
              onApprove([row.itemSiteId]);
              handleCloseMenu();
            }}
            disabled={!approveEnabled}
          >
            Approve
          </MenuItem>
        )}
        {userHasAccessTo(APPROVE_SITE_PAYMENTS) && (
          <MenuItem
            onClick={() => {
              openRejectModal([row.itemSiteId], applyFilter);
              handleCloseMenu();
            }}
            disabled={!rejectEnabled}
          >
            Reject
          </MenuItem>
        )}
        {userHasAccessTo(APPROVE_SITE_PAYMENTS) && (
          <MenuItem
            onClick={() => {
              openMarkAsPaidModal([row.itemSiteId], applyFilter);
              handleCloseMenu();
            }}
            disabled={!markAsPaidEnabled}
          >
            Mark as Paid
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
