import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NonProtocolEncounterApi, PreScreenApi } from '../../../../api';

import { generateLink } from './worklistService';

export function StartEncounterButton({ sitePatientId, disabled, encounterIdentifier, elementIdentifier, children }) {
  const navigate = useNavigate();
  const encounterDetails = {
    protocolEncounterId: encounterIdentifier
  };

  function startEncounter() {
    NonProtocolEncounterApi.createNonProtocolEncounter(sitePatientId, encounterDetails).then(
      ({ data: ssuPatientEncounterId }) => {
        PreScreenApi.getLinkConstructor(sitePatientId, ssuPatientEncounterId, elementIdentifier).then(({ data }) =>
          navigate(generateLink(data))
        );
      }
    );
  }
  if (!disabled) {
    return <span onClick={startEncounter}>{children}</span>;
  } else {
    return <span>{children}</span>;
  }
}

export default StartEncounterButton;
