import React from 'react';
import cx from 'classnames';

import Icon from '../../../../common/general/Icon';

import './PasswordStrengthMeter.scss';

export default function PasswordStrengthMeter({
  validator_12chars,
  validator_upperLower,
  validator_passwords_match,
  validator_1digit,
  validator_special
}) {
  return (
    <div className="auth-box-password-strength-meter">
      <h5>For better security, the password must consist of:</h5>

      <div className={cx({ checked: validator_12chars })}>
        <Icon>done</Icon>
        At least 12 characters long
      </div>

      <div className={cx({ checked: validator_upperLower })}>
        <Icon>done</Icon>
        Uppercase and lowercase letters
      </div>

      <div className={cx({ checked: validator_1digit })}>
        <Icon>done</Icon>
        Number
      </div>

      <div className={cx({ checked: validator_special })}>
        <Icon>done</Icon>
        Special character
      </div>

      <div className={cx({ checked: validator_passwords_match })}>
        <Icon>done</Icon>
        Password match
      </div>
    </div>
  );
}
