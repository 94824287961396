import axios from 'axios';

const ROOT = 'api/v1/finance/budgets';

const FinBudgetApi = {
  copy(id) {
    return axios.post(`${ROOT}/copy`, { id });
  },
  export(id) {
    return axios.get(`${ROOT}/${id}/export`, { responseType: 'arraybuffer' });
  },
  statutes() {
    return axios.get(`${ROOT}/statuses`);
  },
  find(id) {
    return axios.get(`${ROOT}/${id}`);
  },
  isEditable(budgetUniqueIdentifier) {
    return axios.get(`${ROOT}/${budgetUniqueIdentifier}/editPossibility`);
  },
  update(studyBudget) {
    return axios.put(`${ROOT}/${studyBudget.id}`, studyBudget);
  },
  save(budget) {
    return axios.post(ROOT, budget);
  },
  findAll() {
    return axios.get(ROOT);
  }
};

export default FinBudgetApi;
