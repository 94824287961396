import React, { useEffect, useState } from 'react';
import PortalPatientApi from 'api/patient/PortalPatientApi';
import ModalBoxes, { useModalBoxes } from 'common/feedback/ModalBoxes/ModalBoxes';
import Button from 'common/general/Button';
import ButtonGroup from 'common/general/ButtonGroup';
import NotificationManager from 'common/notifications/NotificationManager';
import { onRequestError } from 'services/handlers';
import { getPatientFullName } from 'services/patient';

import './PatientPortalInvitationModal.scss';

export default function PatientPortalInvitationModal({ modalBox, patientInfo }) {
  const { id, email } = patientInfo;
  const { loading, setLoading } = useModalBoxes();
  const [duplicate, setDuplicate] = useState(null);

  useEffect(() => {
    setLoading(true);
    PortalPatientApi.getPatientWithDuplicatedEmail(id, email)
      .then(function({ data }) {
        if (data?.email) {
          setDuplicate(data);
        }
      })
      .finally(function() {
        setLoading(false);
      });
  }, [email, id, setLoading]);

  return (
    <>
      <ModalBoxes.Header>{!duplicate ? 'Patient Portal Invitation' : 'Duplicate Patient Found'}</ModalBoxes.Header>
      <ModalBoxes.Body>
        {!loading && !duplicate && `Patient email: ${email}`}
        {!loading && duplicate && (
          <>
            <p>
              A patient named {getPatientFullName(duplicate)} matching email {duplicate.email} and having patient ID's{' '}
              {duplicate.patientId} was found.
            </p>
            <b>Portal Invitation can be sent only to unique emails</b>
          </>
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          {!loading && !duplicate && <Button onClick={invite}>Invite</Button>}
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
  function invite() {
    setLoading(true);
    PortalPatientApi.inviteToPortal(id).then(
      function({ data }) {
        NotificationManager.success(`Success! The patient invite has been sent via email.`);
        modalBox.close(data);
      },
      function(e) {
        modalBox.close(e);
        onRequestError(e);
      }
    );
  }
}

PatientPortalInvitationModal.className = 'patient-portal-invitation-modal';
PatientPortalInvitationModal.size = 'w650';
