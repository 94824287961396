import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactTable from 'react-table';
import cx from 'classnames';
import moment from 'moment';

import PatientStatusApi from '../../../../../api/patient/PatientStatusApi';
import StatusChangeSupplierApi from '../../../../../api/patient/StatusChangeSupplierApi';
import Section from '../../../../../common/data-display/Section/Section';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import FloatingPanel from '../../../../../common/fullScreenLayout/FloatingPanel';
import Button from '../../../../../common/general/Button';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { PATIENT_STATUS_CHANGED } from '../../../../../constants/notificationMessages';
import { CLOSED } from '../../../../../constants/ssuStatuses';
import { MANAGE_SSU_PATIENT_STATUSES } from '../../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../../services/auth';
import { onRequestError } from '../../../../../services/handlers';
import { getPatientAgeFromDob, getPatientFullName, getPatientPreferredName } from '../../../../../services/patient';
import { CellFormattedDate } from '../../../../CellFormattedDate/CellFormattedDate';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { useCurrentRoute } from '../../../../root/router';
import { PatientInfoContext, PatientInfoProvider } from '../PatientInfo/PatientInfoContext';

import PatientStatusChangeEdit from './PatientStatusChangeEdit/PatientStatusChangeEdit';
import {
  addRecordToStatusHistory,
  prepareHistory,
  thereAreMoreActiveRecordInsteadSpecified
} from './PatientStatusChangeEdit/PatientStatusChangeEditService';
import PatientStatusRemoveModal from './PatientStatusChangeRemove/PatientStatusRemoveModal';
import PatientStatusProgress from './PatientStatusProgress/PatientStatusProgress';
import { AllowedStatusesResolver } from './AllowedStatusesResolver';
import { dateFormatForShow, statusChangeLocations, statusChangeModes } from './PatientStatusChangeConstants';

import './PatientStatusChange.scss';

function PatientStatusChange() {
  const { patientInfo, currentStudy, updatePatientRelatedStudies } = useContext(PatientInfoContext);
  const [patientStatusHistory, setPatientStatusHistory] = useState([]);
  const [originalPatientStatusHistory, setOriginalPatientStatusHistory] = useState([]);
  const ssuPatientId = useCurrentRoute().params?.ssuPatientId;
  const [viewMode, setViewMode] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [encounters, setEncounters] = useState([]);
  const [progressTrigger, setProgressTrigger] = useState(true);
  const [missingStatus, setMissingStatus] = useState(null);

  useEffect(() => {
    PatientStatusApi.getAvailableStatuses(ssuPatientId).then(({ data: patientStatuses }) => {
      const statuses = patientStatuses.filter(new AllowedStatusesResolver().getAllowedStatuses());
      setStatuses(statuses);
    });
  }, [ssuPatientId, setStatuses]);

  const getPatientStatusHistory = useCallback(() => {
    PatientStatusApi.getStatusChangeRecords(ssuPatientId).then(({ data: statusRecords }) => {
      const history = prepareHistory(statusRecords);
      setPatientStatusHistory(history);
      setOriginalPatientStatusHistory(history);
    });
  }, [ssuPatientId, setPatientStatusHistory]);

  useEffect(() => {
    StatusChangeSupplierApi.getCheckedInEncounters(ssuPatientId).then(({ data: encounters }) => {
      setEncounters(encounters);
    });
  }, [ssuPatientId]);

  useEffect(getPatientStatusHistory, [getPatientStatusHistory]);

  const isValidDate = dateToTest => resolveValidator(originalPatientStatusHistory, viewMode)?.(dateToTest);

  const addNewStatusPreview = useCallback(
    draftStatusChangeRecord => {
      setPatientStatusHistory(addRecordToStatusHistory(originalPatientStatusHistory, draftStatusChangeRecord));
    },
    [originalPatientStatusHistory]
  );

  const updatePatientStatusHistoryWithOriginalValues = useCallback(() => {
    setPatientStatusHistory(originalPatientStatusHistory);
  }, [originalPatientStatusHistory]);

  const removeStatus = statusRecordToRemove => {
    const patientStatusRemoveModal = ModalBoxes.open({
      component: (
        <PatientStatusRemoveModal
          patientStatusHistory={originalPatientStatusHistory}
          statusToRemove={statusRecordToRemove}
          removeStatus={(sitePatientHistoryId, deactivationComment) => {
            PatientStatusApi.deactivatePatientStatus(ssuPatientId, { sitePatientHistoryId, deactivationComment })
              .then(() => {
                getPatientStatusHistory();
                updatePatientRelatedStudies();
                patientStatusRemoveModal.close();
                setProgressTrigger(!progressTrigger);
              })
              .catch(err => {
                onRequestError(err);
                patientStatusRemoveModal.close();
              });
          }}
        />
      )
    });
  };

  const isAllowedToManagePatientStatuses =
    userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
    (currentStudy?.studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_SSU_PATIENT_STATUSES));

  const columns = [
    {
      Header: 'Date',
      accessor: 'statusDate',
      width: 140,
      Cell: ({ value }) => <CellFormattedDate date={value} />
    },
    {
      id: 'statusName',
      Header: 'Status',
      accessor: row => {
        const status = row?.status?.name || '';
        return row.active === false ? <strike>{status}</strike> : status;
      },
      width: 160
    },
    {
      Header: 'Encounter',
      accessor: 'encounter',
      width: 200,
      Cell: ({ value }) => value?.name || 'N/A'
    },
    {
      id: 'statusChangeLocation',
      Header: 'Change Location',
      width: 160,
      accessor: row => {
        return row.statusChangeLocation === 'ITEMGROUP'
          ? row.itemGroup?.name || ''
          : statusChangeLocations[row.statusChangeLocation] || '';
      }
    },
    {
      Header: 'Date Changed',
      accessor: 'creationDate',
      width: 120,
      Cell: ({ value }) => <CellFormattedDate date={value} />
    },
    {
      Header: 'Changed By',
      accessor: 'changedBy',
      width: 200,
      Cell: ({ value }) => (value ? `${value.firstName} ${value.lastName}` : 'N/A')
    },
    {
      Header: 'Reason',
      minWidth: 235,
      Cell: ({ original: statusChangeRecord }) => {
        return (
          <div className="reason-cell">
            {statusChangeRecord?.reason?.name && (
              <React.Fragment>
                <div className="short-reason">
                  {statusChangeRecord?.reason?.name}: {statusChangeRecord?.comment}
                </div>
                <div className="full-reason">
                  {statusChangeRecord?.reason?.name}: {statusChangeRecord?.comment}
                </div>
              </React.Fragment>
            )}
          </div>
        );
      }
    },
    {
      id: 'removeStatus',
      Header: '',
      width: 90,
      accessor: statusRecord => {
        if (
          statusRecord.active &&
          thereAreMoreActiveRecordInsteadSpecified(originalPatientStatusHistory, statusRecord)
        ) {
          return (
            <Button
              disabled={!isAllowedToManagePatientStatuses}
              onClick={() => {
                removeStatus(statusRecord);
              }}
              size="h28"
              priority="low"
            >
              Remove
            </Button>
          );
        } else {
          return '';
        }
      }
    }
  ];

  const getTrProps = (state, rowInfo) => {
    return { className: cx({ highlight: !rowInfo?.original?.id, 'grey-out': rowInfo?.original.active === false }) };
  };

  const filteredStatuses = useMemo(() => {
    return statuses.filter(status => (viewMode === statusChangeModes.ADD ? status.id !== 'TRANSFERRING' : true));
  }, [statuses, viewMode]);

  return (
    <>
      <PageInfoHeader
        objectRecordLabel={getPatientFullName(patientInfo) + getPatientPreferredName(patientInfo)}
        pageInfo={
          <PageInfoHeader.CollapsibleList>
            <PageInfoHeader.AdditionalInfo title="Patient ID">{patientInfo?.patientId}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo title="Subject ID">
              {currentStudy?.patientSubjectId || 'No Subject ID'}
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Date of Birth">
              {patientInfo?.dob?.format(dateFormatForShow)} ({getPatientAgeFromDob(patientInfo)})
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Study">{currentStudy?.studyName}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Site">{currentStudy?.siteName}</PageInfoHeader.AdditionalInfo>
          </PageInfoHeader.CollapsibleList>
        }
      />
      <div className="patient-status-change">
        <Section className="info-section">
          <div className="row p-3 m-0 status-bar">
            <strong>Current Status: {currentStudy?.patientStatus || '---'}</strong>
            <Button
              onClick={() => {
                setViewMode(statusChangeModes.EDIT);
                setMissingStatus(null);
              }}
              size="h28"
              priority="high"
              className="ml-2"
              disabled={!isAllowedToManagePatientStatuses}
            >
              Change
            </Button>
          </div>
        </Section>
        <Section className="status-progress-section">
          <PatientStatusProgress
            ssuPatientId={ssuPatientId}
            progressTrigger={progressTrigger}
            addMissingStatus={statusName => {
              setMissingStatus(statusName);
              setViewMode(statusChangeModes.ADD);
            }}
            displayAddButton
          />
        </Section>
        <Section className="content-section">
          <div className="px-3 pt-3 section-bar">
            <strong>Status History</strong>
            <Button
              onClick={() => {
                setViewMode(statusChangeModes.ADD);
                setMissingStatus(null);
              }}
              size="h28"
              priority="low"
              className="ml-2 missing-status-btn"
              disabled={!isAllowedToManagePatientStatuses}
            >
              + Missing status
            </Button>
          </div>
          <ReactTable
            data={patientStatusHistory}
            columns={columns}
            minRows={1}
            multiSort0
            className="eui-table status-history-table"
            showPagination={false}
            resizable={true}
            sortable={false}
            getTrProps={getTrProps}
          />
        </Section>
        <FloatingPanel
          show={viewMode === statusChangeModes.EDIT || viewMode === statusChangeModes.ADD}
          close={discardChanges}
        >
          <PatientStatusChangeEdit
            viewMode={viewMode}
            statuses={filteredStatuses}
            onStatusRecordChanged={addNewStatusPreview}
            encounters={encounters}
            patientStatusHistory={patientStatusHistory}
            close={discardChanges}
            removeDraftStatuses={updatePatientStatusHistoryWithOriginalValues}
            isValidDate={isValidDate}
            initialStatus={missingStatus}
            updateStatus={(status, reason, date, encounter, comment, nextStatusChangeId) => {
              let updateStatus;
              switch (viewMode) {
                case statusChangeModes.ADD:
                  updateStatus = PatientStatusApi.addStatus;
                  break;
                case statusChangeModes.EDIT:
                default:
                  updateStatus = PatientStatusApi.changeStatus;
                  break;
              }
              return updateStatus(ssuPatientId, {
                statusCodeId: status?.id,
                withdrawReason: reason?.id,
                comment,
                ssuPatientEncounterId: encounter?.id,
                // prevents date shift timezone issue backend LocalDate ignore zone
                statusDate: date.format('YYYY-MM-DD'),
                statusChangeLocation: 'STATUS_HISTORY',
                nextStatusChangeId
              })
                .then(() => {
                  NotificationManager.success(PATIENT_STATUS_CHANGED);
                  setViewMode(statusChangeModes.VIEW);
                  getPatientStatusHistory();
                  updatePatientRelatedStudies();
                  setProgressTrigger(!progressTrigger);
                  setMissingStatus(null);
                })
                .catch(err => onRequestError(err));
            }}
          />
        </FloatingPanel>
      </div>
    </>
  );

  function discardChanges() {
    setViewMode(statusChangeModes.VIEW);
    updatePatientStatusHistoryWithOriginalValues();
    setMissingStatus(null);
  }
}

export default function PatientStatusChangeComponent(props) {
  return (
    <PatientInfoProvider>
      <PatientStatusChange {...props} />
    </PatientInfoProvider>
  );
}

function resolveValidator(history, statusChangeMode) {
  const validators = {
    [statusChangeModes.EDIT]: date => {
      let start = moment(history.find(s => s.active).statusDate);
      let now = moment();
      return date.isBetween(start, now, 'day', '[]');
    },
    [statusChangeModes.ADD]: date => {
      let now = moment();
      return date.isBefore(now);
    }
  };
  return validators[statusChangeMode];
}
