import React from 'react';

import modalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { OutreachAttemptTrackingModal } from '../../../patient-source/shared/NewEncounterPage/OutreachAttemptTrackingModal/OutreachAttemptTrackingModal';

export const viewAttempts = ssuPatientId => {
  modalBoxes.open({
    component: <OutreachAttemptTrackingModal ssuPatientId={ssuPatientId} viewOnly={true} />,
    title: 'Contact Attempt Tracking',
    size: 'w1100',
    className: 'outreach-attempt-tracking-modal'
  });
};
