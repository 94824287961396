import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, isEqual } from 'lodash/lang';
import { get } from 'lodash/object';
import { bindActionCreators } from 'redux';

import workListFilteringDataAction from '../../../../actions/workListFilteringDataAction';
import { StudySiteApi } from '../../../../api';
import Select from '../../../../common/data-entry/Select';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { NO_PRE_SCREEN_PROTOCOL } from '../../../../constants/notificationMessages';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';
import { SSUFilterRepresentation } from '../../../SSUFilter/SSUFilterRepresentation';

export class WorklistFilter extends Component {
  /**
   * Update Redux state. Clear status if site or study has been changed.
   */
  onSSUFilterChange = (ssuList, selectedStudy, selectedSite) => {
    const studySiteData = {
      selectedStudyUniqueIdentifier: selectedStudy ? selectedStudy.uniqueIdentifier : null,
      selectedSite: selectedSite ? { name: selectedSite.siteName, id: selectedSite.uniqueIdentifier } : null
    };
    const dataForSave = this.clearStatusIfSiteOrStudyHasBeenChanged(selectedStudy, selectedSite, studySiteData);
    this.props.workListFilteringDataAction(this.generateWorklistFilteringData(dataForSave));
  };

  clearStatusIfSiteOrStudyHasBeenChanged(selectedStudy, selectedSite, dataForSave) {
    const selectedStudyUniqueIdentifier = get(this, 'props.workListFilteringData.selectedStudyUniqueIdentifier');
    const selectedWorkListStatus = get(this, 'props.workListFilteringData.selectedWorkListStatus');
    if (!isEqual(selectedStudyUniqueIdentifier, get(selectedStudy, 'uniqueIdentifier'))) {
      return { selectedWorkListStatus: null, ...dataForSave };
    }
    return { selectedWorkListStatus, ...dataForSave };
  }

  onWorkListStatusChange = status => {
    const selectedWorkListStatus = get(this, 'props.workListFilteringData.selectedWorkListStatus');
    if (!isEqual(status, selectedWorkListStatus)) {
      this.props.workListFilteringDataAction(this.generateWorklistFilteringData({ selectedWorkListStatus: status }));
    }
  };

  loadSsuWithOutreachOrChartReviewIG = () => {
    return StudySiteApi.getStudySiteWithNonProtocolEncounter().then(({ status = null, data = [] }) => {
      data = data.map(item => ({
        ...item,
        study: { uniqueIdentifier: item.study.id, studyName: item.study.name },
        site: { uniqueIdentifier: item.site.id, siteName: item.site.name },
        siteIdentifier: item.site.id,
        studyIdentifier: item.study.id
      }));
      if (status === 204) {
        NotificationManager.warning(NO_PRE_SCREEN_PROTOCOL);
      }
      const selectedStudyUniqueIdentifier = get(this, 'props.workListFilteringData.selectedStudyUniqueIdentifier');
      const selectedStudyUniqueIdentifierIsEmptyOrExists =
        isEmpty(selectedStudyUniqueIdentifier) || data.some(r => r.studyIdentifier === selectedStudyUniqueIdentifier);
      if (!selectedStudyUniqueIdentifierIsEmptyOrExists) {
        this.props.workListFilteringDataAction(
          this.generateWorklistFilteringData({ selectedStudyUniqueIdentifier: null, selectedSite: null })
        );
      }
      return data;
    });
  };

  generateWorklistFilteringData = savedData => {
    const workListFilteringData = get(this, 'props.workListFilteringData') || {};
    return { ...workListFilteringData, ...savedData };
  };

  render() {
    const selectedWorkListStatus = get(this, 'props.workListFilteringData.selectedWorkListStatus');
    const selectedStudyUniqueIdentifier = get(this, 'props.workListFilteringData.selectedStudyUniqueIdentifier');
    const selectedSiteId = get(this, 'props.workListFilteringData.selectedSite.id');
    return (
      <div className="work-list-filter">
        <div className="general-header-group-container general-header-wrapper">
          <SSUFilter
            handleSSUFilterChange={this.onSSUFilterChange}
            studyIdProvider={() => selectedStudyUniqueIdentifier}
            siteIdProvider={() => selectedSiteId}
            isStudyRequired={true}
            clearable={true}
            ssuProvider={this.loadSsuWithOutreachOrChartReviewIG}
          >
            <SSUFilterRepresentation isRequired={true} />
          </SSUFilter>
          <Select
            label="Worklist Status"
            clearSearchOnSelection
            optionLabelKey="name"
            optionValueKey="id"
            dataSource={this.props.workListStatuses}
            onChange={e => this.onWorkListStatusChange(e || null)}
            value={selectedWorkListStatus || null}
            searchable
            validate={false}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ workListFilteringData }) => {
  return { workListFilteringData };
};

const mapDispatchToProps = dispatch => {
  return {
    workListFilteringDataAction: bindActionCreators(workListFilteringDataAction, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorklistFilter);
