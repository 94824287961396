import React from 'react';
import { ConsoleLogger as Logger, I18n, JS } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import { RequireNewPassword } from 'aws-amplify-react';
import { find } from 'lodash';

import { UserApiApi } from '../../../../api';
import { addCognitoAccessTokenInterceptor } from '../../../../api/interceptors';
import Input from '../../../../common/data-entry/Input';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import ModalBoxesContainer from '../../../../common/feedback/ModalBoxes/ModalBoxesContainer/ModalBoxesContainer';
import Button from '../../../../common/general/Button';
import { withAppInfo } from '../../../../common/hocs/withAppInfo';
import AuthBox from '../AuthBox/AuthBox';
import PasswordStrengthMeter from '../PasswordStrengthMeter/PasswordStrengthMeter';
import TermsOfUseModal from '../TermsOfUseModal/TermsOfUseModal';

const logger = new Logger('RequireNewPassword');

class CustomNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);

    this._validAuthStates = ['requireNewPassword'];
    this.change = this.change.bind(this);
    this.checkContact = this.checkContact.bind(this);

    this.state = {
      validator_12chars: false,
      validator_upperLower: false,
      validator_1digit: false,
      validator_special: false
    };

    this.validator12Char = this.validator12Char.bind(this);
    this.validator1Digit = this.validator1Digit.bind(this);
    this.validatorUpperAndLower = this.validatorUpperAndLower.bind(this);
    this.validatorSpecialChar = this.validatorSpecialChar.bind(this);
  }

  async checkContact(user) {
    if (!Auth || typeof Auth.verifiedContact !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.verifiedContact(user)
      .then(data => {
        if (!JS.isEmpty(data.verified)) {
          window.history.pushState({}, '', '/');
          this.changeState('signedIn', user);
          this.props?.appInfo?.features?.auth0Enabled && window.location.reload();
        } else {
          user = Object.assign(user, data);
          this.changeState('verifyContact', user);
        }
      })

      // Begin Modified Code
      .then(() => {
        user.getUserAttributes((err, attributes) => {
          if (err) {
            throw err;
          } else {
            const emailObj = find(attributes, atr => {
              if (atr.getName() === 'email') {
                return atr.getValue();
              }
            });
            const payload = {
              userPoolId: Auth.configure().userPoolId,
              userName: emailObj.getValue()
            };
            return UserApiApi.updatePasswordExpire(payload).catch(err => console.error(err));
          }
        });
      });
    // End Modified Code
  }

  /*
   * Calls API to determine whether this is a user's first login to DirectSource.
   * The result will either be true or false.
   * If true, show the terms and sign out if rejected or carry on if accepted.
   * The API call will fail if the user is not yet synced from Central.
   * The user is notified of errors through the toast bar
   */
  async checkFirstTimeLogin() {
    try {
      // if using auth0 we'll check TermsOfUse in CurrentUserContainer.js
      if (this.props?.appInfo?.features?.auth0Enabled) {
        return Promise.resolve();
      } else {
        const firstLogin = await UserApiApi.checkFirstTimeLogin();
        if (firstLogin.data.response) {
          try {
            await ModalBoxes.confirm({
              content: <TermsOfUseModal />,
              className: 'terms-of-use-modal',
              title: 'IntElligo® Terms of Use',
              confirmButton: 'Accept'
            });
          } catch (e) {
            await Auth.signOut();
            const msg =
              'Your password has been updated. You will not be able to login until you accept the Terms of Use';
            return Promise.reject(msg);
          }
        }
        return Promise.resolve();
      }
    } catch (err) {
      console.error(err);
      const msg =
        'Account generation is still in progress. Your password has been updated. Please try logging in again in 10 minutes';
      return Promise.reject(msg);
    }
  }

  async getCognitoUserRequirePassword(email, pw) {
    try {
      const user = await Auth.signIn(email.trim().toLowerCase(), pw.trim());
      logger.debug(user);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return user;
      } else {
        await Auth.signOut();
      }
      return null;
    } catch (err) {
      logger.debug(err);
    }
    return null;
  }

  async change(event) {
    if (event) {
      event.preventDefault();
    }
    const {
      validator_12chars,
      validator_upperLower,
      validator_passwords_match,
      validator_1digit,
      validator_special
    } = this.state;
    const passwordValid =
      validator_upperLower && validator_12chars && validator_1digit && validator_special && validator_passwords_match;

    if (!passwordValid) {
      return;
    }

    const { isFromLink = false, tmpPw, username } = this.props.authData;
    let user;
    let given_name;
    let family_name;
    if (isFromLink) {
      user = await this.getCognitoUserRequirePassword(username, tmpPw);
      if (!user) {
        this.error('Link expired');
        return;
      }
      ({ given_name, family_name } = user.challengeParam);
    } else {
      user = this.props.authData;
      ({ given_name, family_name } = this.inputs);
    }
    const { password } = this.inputs;

    const { userAttributes } = user.challengeParam;

    if (!Auth || typeof Auth.completeNewPassword !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    Auth.completeNewPassword(user, password)
      .then(user => {
        addCognitoAccessTokenInterceptor();
        logger.debug('complete new password', user);
        if (given_name || family_name) {
          const params = {
            given_name: given_name || userAttributes.given_name,
            family_name: family_name || userAttributes.family_name
          };
          Auth.updateUserAttributes(user, params)
            .then(user => {
              const { given_name, family_name } = user.challengeParam.userAttributes;
              logger.debug(`Successfully changed the name of ${given_name} ${family_name}`);
            })
            .catch(err => this.error(err));
        }
        if (user.challengeName === 'SMS_MFA') {
          this.changeState('confirmSignIn', user);
        } else if (user.challengeName === 'MFA_SETUP') {
          logger.debug('TOTP setup', user.challengeParam);
          this.changeState('TOTPSetup', user);
        } else {
          // this block modified from original Cognito code
          this.checkFirstTimeLogin()
            .then(() => this.checkContact(user))
            .catch(msg => {
              Auth.signOut().then(() => {
                this.error(msg);
              });
            });
          // end of modified original code
        }
      })
      .catch(err => this.error(err));
  }

  validator12Char() {
    const { password } = this.inputs;
    if (password && password.length >= 12) {
      this.setState({
        validator_12chars: true
      });
    } else {
      this.setState({
        validator_12chars: false
      });
    }
  }

  validator1Digit() {
    const { password } = this.inputs;
    const regex = new RegExp(/\d/);
    if (password) {
      this.setState({
        validator_1digit: regex.test(password)
      });
    } else {
      this.setState({
        validator_1digit: false
      });
    }
  }

  validatorUpperAndLower() {
    const { password } = this.inputs;
    const regex = new RegExp(/(?=.*[a-z])(?=.*[A-Z])/);
    if (password) {
      this.setState({
        validator_upperLower: regex.test(password)
      });
    } else {
      this.setState({
        validator_upperLower: false
      });
    }
  }

  validatorSpecialChar() {
    const { password } = this.inputs;
    const regex = new RegExp(/(?=.*\W)/);
    if (password) {
      this.setState({
        validator_special: regex.test(password)
      });
    } else {
      this.setState({
        validator_special: false
      });
    }
  }

  validatorPasswordsMatch() {
    const { password, confirmPassword } = this.inputs;
    if (password === confirmPassword && password.length && confirmPassword.length) {
      this.setState({
        validator_passwords_match: true
      });
    } else {
      this.setState({
        validator_passwords_match: false
      });
    }
  }

  showComponent(theme) {
    const { props, state } = this;
    const { hide, authData = {} } = props;
    if (hide && hide.includes(CustomNewPassword)) {
      return null;
    }

    const { isFromLink = false } = authData;
    const { userAttributes } = authData.challengeParam;

    const {
      validator_12chars,
      validator_upperLower,
      validator_passwords_match,
      validator_1digit,
      validator_special
    } = state;

    const passwordValid =
      validator_upperLower && validator_12chars && validator_1digit && validator_special && validator_passwords_match;

    const headerAppend = !!userAttributes?.email && <b> for {userAttributes.email}</b>;
    const appInfo = this.props?.appInfo;

    return (
      <React.Fragment>
        <AuthBox>
          <form onSubmit={this.change}>
            <div className="auth-box-header">
              {I18n.get('Complete user profile')}
              {headerAppend}
            </div>
            <div className="auth-box-fields">
              {!isFromLink && (
                <React.Fragment>
                  <Input
                    readOnly
                    disabled
                    label={I18n.get('First Name')}
                    key="given_name"
                    name="given_name"
                    value={userAttributes.given_name}
                    type="text"
                  />
                  <Input
                    readOnly
                    disabled
                    label={I18n.get('Last Name')}
                    key="family_name"
                    name="family_name"
                    value={userAttributes.family_name}
                    type="text"
                  />
                </React.Fragment>
              )}
              <Input.Password
                autoFocus
                required
                label={I18n.get('Create Password')}
                key="password"
                name="password"
                onChange={event => {
                  this.handleInputChange(event);
                  this.validator12Char();
                  this.validator1Digit();
                  this.validatorUpperAndLower();
                  this.validatorSpecialChar();
                  this.validatorPasswordsMatch();
                }}
              />
              <Input.Password
                required
                label={I18n.get('Confirm Password')}
                key="confirmPassword"
                name="confirmPassword"
                autoComplete="new-password"
                onChange={event => {
                  this.handleInputChange(event);
                  this.validatorPasswordsMatch();
                }}
              />
            </div>
            <PasswordStrengthMeter
              validator_12chars={validator_12chars}
              validator_upperLower={validator_upperLower}
              validator_passwords_match={validator_passwords_match}
              validator_1digit={validator_1digit}
              validator_special={validator_special}
            />
            <div className="auth-box-buttons custom-new-password-buttons">
              <Button
                type="button"
                size="h56"
                priority="low"
                onClick={() => {
                  window.history.pushState({}, '', '/');
                  this.changeState('signIn');
                  appInfo?.features?.auth0Enabled && window.location.reload();
                }}
              >
                {I18n.get('Return to Sign In Page')}
              </Button>
              <Button type="submit" size="h56" disabled={!passwordValid}>
                {I18n.get('Submit')}
              </Button>
            </div>
          </form>
        </AuthBox>
        <ModalBoxesContainer />
      </React.Fragment>
    );
  }
}

const CustomNewPasswordWithAppInfo = withAppInfo(CustomNewPassword);
export { CustomNewPasswordWithAppInfo as CustomNewPassword };
