import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import { Checkbox, FormControlLabel } from '@mui/material';
import { isEqual } from 'lodash/lang';

import { withEuiFiltersProvider } from 'components/eui/EuiFiltersContext/EuiFiltersContext';

import { StudyApi } from '../../../../api';
import Common from '../../../../common/common';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import ButtonGroup from '../../../../common/general/ButtonGroup';
import Icon from '../../../../common/general/Icon';
import { CANCELED } from '../../../../constants/ssuStatuses';
import { STDT_REIMBURSEMENT, STTG_CTMS } from '../../../../constants/study/studyTypes';
import {
  MANAGE_PATIENT_REIMBURSEMENT_SELF_SUBMISSION,
  MANAGE_STUDY_DETAILS,
  VIEW_STUDY_SITE_DETAILS
} from '../../../../constants/userOperations';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { userHasAccessTo } from '../../../../services/auth';
import { onRequestError } from '../../../../services/handlers';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import PrintPatientEncountersGeneratorModal from '../../../PrintPatientEncountersGeneratorModal/PrintPatientEncountersGeneratorModal';
import { generateUrlByKey } from '../../../root/router';

import FiltersSection from './FiltersSection/FiltersSection';
import StudyTypeSetup from './StudyTypeSetup/StudyTypeSetup';
import InformedConsentSetup from './InformedConsentSetup';
import StatusesSetup from './StatusesSetup';

import 'react-table/react-table.css';
import './StudyDetails.scss';

class StudyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studyIdentifier: null,
      SiteDetailsColumns: [],
      studyDetails: {},
      viewProtocolLink: null,
      typeParents: null,
      type: null
    };
  }

  showEncountersPrintVersionGenerator = () => {
    ModalBoxes.open({
      component: <PrintPatientEncountersGeneratorModal studyIdentifier={this.state.studyIdentifier} type={2} />
    });
  };

  onStudyTypeAndParentChange = (type, typeParents) => {
    this.setState({ type, typeParents });
  };

  onStudyChange = studyIdentifier => {
    StudyApi.getStudyTypes(studyIdentifier).then(({ data }) => {
      this.onStudyTypeAndParentChange(data.type, data.typeParents);
    }, onRequestError);

    if (studyIdentifier && !isEqual(studyIdentifier, this.state.studyIdentifier)) {
      this.setState({ studyIdentifier });
      StudyApi.populateStudyDetailsByIdentifier(studyIdentifier).then(res => {
        const SiteDetailsColumns = [
          {
            Header: 'Site',
            accessor: 'site_name',
            Cell: row => {
              return userHasAccessTo(VIEW_STUDY_SITE_DETAILS) ? (
                <NavLink
                  className="nav-link"
                  to={`/study-details/siteDetails/${row.row._original.unique_identifier}/${studyIdentifier}`}
                >
                  {row.row.site_name}
                </NavLink>
              ) : (
                row.row.site_name
              );
            },
            className: 'activelink'
          },
          {
            Header: 'Site Number',
            accessor: 'siteNumber',
            Cell: ({ value }) => (value && <span>{value}</span>) || '--'
          },
          {
            Header: 'Status',
            accessor: 'site_status',
            Cell: row => {
              return row.row.site_status ? <span> {row.row.site_status}</span> : '--';
            }
          },
          {
            Header: 'Lead Investigator',
            accessor: 'primary_investigator',
            Cell: row => {
              return row.row.primary_investigator ? <span> {row.row.primary_investigator}</span> : '--';
            }
          },
          {
            Header: 'Pre-Study Visit',
            accessor: 'psvDate',
            Cell: ({ value }) => (value && <span>{value}</span>) || '--',
            sortMethod: Common.dateComparator
          },
          {
            Header: 'SIVDate',
            accessor: 'sivDate',
            Cell: ({ value }) => (value && <span>{value}</span>) || '--',
            sortMethod: Common.dateComparator
          }
        ];
        const studyDetails = res?.data?.response || {};
        studyDetails.listOfSites = studyDetails.listOfSites?.filter(site => site?.site_status !== CANCELED) || [];
        this.setState({ studyDetails, SiteDetailsColumns });
        this.buildViewProtocolLink(studyIdentifier, res.data.response.study_name);
      }, onRequestError);
    }
  };

  buildViewProtocolLink = (studyId, studyName) => {
    StudyApi.getProtocolsByStudyIdentifier(studyId).then(res => {
      if (res.data && Array.isArray(res.data) && res.data.length > 0 && res.data.some(p => p.status === 'Active')) {
        const activeProtocol = res.data.find(p => p.status === 'Active');
        const url = generateUrlByKey('Study Details.Source Data Preview', {
          studyId,
          protocolIdentity: activeProtocol.protocolId
        });
        this.setState({ viewProtocolLink: url });
      } else {
        this.setState({ viewProtocolLink: null });
      }
    }, onRequestError);
  };

  setPresentEpochs = () => {
    const isPresentEpochs = !this.state.studyDetails.presentEpochs;
    StudyApi.setIsPresentEpochs(this.state.studyDetails.unique_identifier, { isPresentEpochs }).then(({ data }) => {
      this.setState(state => {
        return { studyDetails: { ...state.studyDetails, presentEpochs: data } };
      });
    });
  };

  toggleSmsOptIn = () => {
    const smsOptIn = !this.state.studyDetails.smsOptIn;
    StudyApi.toggleSmsOptIn(this.state.studyDetails.unique_identifier, smsOptIn).then(({ data }) => {
      this.setState(state => {
        return { studyDetails: { ...state.studyDetails, smsOptIn: data } };
      });
    });
  };

  onReimbursementSelfSubmissionEnabledUpdate = reimbursementSelfSubmissionEnabled => {
    StudyApi.updatePatientReimbursementSelfSubmission(
      this.state.studyDetails.unique_identifier,
      reimbursementSelfSubmissionEnabled
    ).then(({ data }) => {
      this.setState(state => {
        return { studyDetails: { ...state.studyDetails, reimbursementSelfSubmissionEnabled: data } };
      });
    });
  };

  handleCostPerMileageUpdate = costPerMileage => {
    return StudyApi.updateCostPerMileage(this.state.studyDetails.unique_identifier, { costPerMileage }).then(
      ({ data }) => {
        this.setState(state => {
          return { studyDetails: { ...state.studyDetails, costPerMileage: costPerMileage } };
        });
      }
    );
  };

  render({ props, state, onStudyChange } = this) {
    const { studyDetails, viewProtocolLink, typeParents, type } = state;
    const divStyle = { minHeight: '444px', border: '1px solid #dee2e6', marginTop: '15px', paddingTop: '146px' };

    const isStudyTypeCTMS = type === STTG_CTMS;
    const isStudyReimbursementOption = typeParents
      ?.find(e => e.typeParent === STTG_CTMS)
      .types?.find(e => e.name === STDT_REIMBURSEMENT)?.selected;

    return (
      <div className="studySiteDetails footpadb iefixflex">
        <PageInfoHeader
          right={
            <ButtonGroup>
              <Button size="h28" priority="medium" to={viewProtocolLink || ''} disabled={!viewProtocolLink}>
                <Icon>visibility</Icon>View Protocol Setup
              </Button>
              <Button size="h28" priority="medium" onClick={this.showEncountersPrintVersionGenerator}>
                <Icon>print</Icon>Print Encounter Forms
              </Button>
            </ButtonGroup>
          }
        >
          <div className="general-header-group-container general-header-wrapper">
            <FiltersSection onStudyChange={onStudyChange} />
          </div>
        </PageInfoHeader>
        <section>
          {Object.keys(studyDetails).length === 0 ? (
            <div style={divStyle} align="center">
              <div className="col-md-3 text-center mt-5 custummsg"> Please select the study.</div>
            </div>
          ) : (
            <div className="p-3 m-0 mt-2 border row justify-content-between">
              <div className="row border-bottom-dotted col-12 m-0 p-0 justify-content-between mb-3">
                <h5 className="pt-2 c-p">
                  Study Details: <span className="text-muted bluetxt">{studyDetails.study_name}</span>
                </h5>
              </div>
              <div className="row">
                <div className="col-2">
                  <div className="form-group">
                    <label htmlFor="site">Name</label>
                    <div className="input-group">
                      <input type="text" className="form-control" value={studyDetails.study_name || ''} disabled />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label htmlFor="site">Phase</label>
                    <div className="input-group">
                      <input type="text" className="form-control" value={studyDetails.study_phase || ''} disabled />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label htmlFor="site">Project Code</label>
                    <div className="input-group">
                      <input type="text" className="form-control" value={studyDetails.project_code || ''} disabled />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label htmlFor="site">Therapeutic Area</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value={studyDetails.therapeutic_area || ''}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label htmlFor="site">Customer</label>
                    <div className="input-group">
                      <input type="text" className="form-control" value={studyDetails.customer || ''} disabled />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label htmlFor="site">Sponsor</label>
                    <div className="input-group">
                      <input type="text" className="form-control" value={studyDetails.sponsor || ''} disabled />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label htmlFor="site">Status</label>
                    <div className="input-group">
                      <input type="text" className="form-control" value={studyDetails.status || ''} disabled />
                    </div>
                  </div>
                </div>
                <div className="col-2 col-half-offset">
                  <div className="form-group">
                    <label htmlFor="site">Screen Fail Rate (%)</label>
                    <div className="input-group">
                      <input type="text" value={studyDetails.screen_failure_rate} className="form-control" disabled />
                    </div>
                  </div>
                </div>
                <div className="col-2 col-half-offset">
                  <div className="form-group">
                    <label htmlFor="site">Drop Rate (%)</label>
                    <div className="input-group">
                      <input type="text" value={studyDetails.drop_out_rate} className="form-control" disabled />
                    </div>
                  </div>
                </div>

                <div className="col-2">
                  <div className="form-group">
                    <label htmlFor="site">CRO</label>
                    <div className="input-group">
                      <input type="text" className="form-control" value={studyDetails.cro || ''} disabled />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <div className="checkboxes">
                      <div>
                        <label htmlFor="nonTraditional">Non-traditional</label>
                        <div className="input-group" style={{ marginTop: '0.375rem' }}>
                          <Checkbox disabled checked={studyDetails.nonTraditional} />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="presentEpochs">Present Epochs</label>
                        <div className="input-group" style={{ marginTop: '0.375rem' }}>
                          <Checkbox
                            disabled={!userHasAccessTo(MANAGE_STUDY_DETAILS)}
                            checked={studyDetails.presentEpochs}
                            onChange={this.setPresentEpochs}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label htmlFor="site">Indication</label>
                    <div className="input-group">
                      <input type="text" className="form-control" value={studyDetails.indication || ''} disabled />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <div className="checkboxes">
                      <div>
                        <label htmlFor="smsOptIn">SMS Opt-In</label>
                        <div className="input-group" style={{ marginTop: '0.375rem' }}>
                          <Checkbox
                            disabled={!userHasAccessTo(MANAGE_STUDY_DETAILS)}
                            checked={studyDetails.smsOptIn}
                            onChange={this.toggleSmsOptIn}
                          />
                        </div>
                      </div>
                      {isStudyTypeCTMS && isStudyReimbursementOption && (
                        <FormControlLabel
                          value="top"
                          control={
                            <Checkbox
                              checked={studyDetails.reimbursementSelfSubmissionEnabled}
                              disabled={!userHasAccessTo(MANAGE_PATIENT_REIMBURSEMENT_SELF_SUBMISSION)}
                              onChange={({ target }) => this.onReimbursementSelfSubmissionEnabledUpdate(target.checked)}
                            />
                          }
                          label="Patient Self Submission of Reimbursement"
                          labelPlacement="top"
                          sx={{ alignItems: 'start', justifyContent: 'space-between', marginBottom: '0px' }}
                          componentsProps={{
                            typography: {
                              sx: { fontSize: '.9rem' }
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="row col-12 m-0 px-3 p-1 ">
                  <div className="col-md-12 row m-0 p-0 mb-2 pb-0 border-bottom-dotted justify-content-between">
                    <h5 className="mt-0 col-md-8 col-sm-auto p-0 c-p ">Site Details</h5>
                  </div>
                  <div className="col-12 table-border p-0">
                    <ReactTable
                      data={this.state.studyDetails.listOfSites}
                      columns={this.state.SiteDetailsColumns}
                      minRows={1}
                      multiSort
                      noDataText="No Record Found"
                      className="table table-responsive-sm activity-table mb-0"
                      showPagination
                      onSortedChange={pendoTrackDefaultSortingChange}
                      nextText=">>"
                      previousText="<<"
                      pageSizeOptions={[25, 50, 100]}
                      defaultPageSize={25}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <StatusesSetup
          studyId={this.state.studyIdentifier}
          status={this.state.studyDetails.status}
          studyName={studyDetails.study_name}
        />
        <InformedConsentSetup studyId={studyDetails.unique_identifier} studyName={studyDetails.study_name} />
        <StudyTypeSetup
          studyDetails={studyDetails}
          handleCostPerMileageUpdate={this.handleCostPerMileageUpdate}
          onStudyTypeAndParentChange={this.onStudyTypeAndParentChange}
        />
      </div>
    );
  }
}

export default withEuiFiltersProvider(StudyDetails, { cachePrefix: 'SDF' });
