import React, { useEffect, useMemo, useState } from 'react';
import SockJsClient from 'react-stomp';
import { Auth } from 'aws-amplify';
import { useAuth0, useBouncer } from 'bouncer';
import { isEmpty, isEqual } from 'lodash/lang';

import cognitoConfig from '../../constants/cognitoConfig';
import { useCurrentUser } from '../root/Container/CurrentUserContainer';

let topicURL = '';

export default function NotificationsWebSocketConnector({ onMessage, onConnectFailure }) {
  const [headers, setHeaders] = useState({});
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const currentUser = useCurrentUser();
  const { isAccessTokenReceived } = useBouncer();
  const { getAccessTokenSilently } = useAuth0();
  const isDevelopmentMode = process.env.NODE_ENV === 'development';

  async function getHeaders() {
    let token;
    if (isAccessTokenReceived) {
      token = await getAccessTokenSilently();
    } else {
      await cognitoConfig();
      const session = await Auth.currentSession();
      token = session.idToken.jwtToken;
    }
    let newHeaders = {};
    if (token && currentUser) {
      newHeaders = {
        'X-Authorization': 'Bearer ' + token,
        'X-Active-Role': currentUser.activeRole
      };
      const newTopicUrl = '/user/' + currentUser.personnelIdentifier + '/notification/item';
      if (newTopicUrl !== topicURL) {
        topicURL = newTopicUrl;
      }
      if (!isEqual(headers, newHeaders)) {
        setHeaders(newHeaders);
        setReloadTrigger(true);
      }
    }
  }

  const SOCKET_URL = useMemo(() => {
    if (isDevelopmentMode) {
      return 'http://localhost:8080/user-notifications';
    }
    return `${window.location.origin}/user-notifications`;
  }, [isDevelopmentMode]);

  useEffect(() => {
    currentUser && getHeaders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <>
      {!isEmpty(headers) && reloadTrigger && (
        <SockJsClient
          url={SOCKET_URL}
          topics={[topicURL]}
          autoReconnect={false}
          options={{ transports: ['websocket'] }}
          onConnect={() => {
            isDevelopmentMode && console.log(`Connected ${currentUser.activeRole} ${currentUser.personnelIdentifier}`);
          }}
          headers={{ ...headers }}
          subscribeHeaders={{ ...headers }}
          onDisconnect={() => {
            isDevelopmentMode && console.log('Disconnected!');
          }}
          onConnectFailure={error => {
            isDevelopmentMode && console.log(error);
            setReloadTrigger(false);
            getHeaders().then(() => {});
          }}
          onMessage={onMessage}
          debug={isDevelopmentMode}
        />
      )}
    </>
  );
}
