import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { Loading, withAuthenticator } from 'aws-amplify-react';
import { BouncerContext } from 'bouncer';
import { isEmpty } from 'lodash/lang';

import NotificationManager from '../../../common/notifications/NotificationManager';
import { LAST_ACTION } from '../../../constants/localStorageConstants';
import { INACTIVITY_LOGOUT } from '../../../constants/notificationMessages';
import { scPurple80 } from '../../../constants/systemColors';
import backgroundImage from '../../../images/login-background-custom.jpg';
import { handleLogout } from '../../../services/auth';
import { register } from '../../../serviceWorker';
import { CustomForgotPassword } from '../login/CustomForgotPassword/CustomForgotPassword';
import { CustomNewPassword } from '../login/CustomNewPassword/CustomNewPassword';
import { CustomSignIn } from '../login/CustomSignIn/CustomSignIn';
import memoizedRouter from '../router/memoizedRouter';

import { CurrentUserContainer } from './CurrentUserContainer';

export const LOGOUT_TIME = 60 * 60 * 1000;
const WARN_TIME = 2 * 60 * 1000; // minutes before logout (0 ms)
const CHECK_RATE = 5 * 1000;

class Container extends Component {
  static contextType = BouncerContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      timeOutWarned: false
    };

    this.events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.reset = this.reset.bind(this);
    this.checkTimeout = this.checkTimeout.bind(this);
    this.cleanup = this.cleanup.bind(this);
    const { bouncerLogout } = this?.context;
    register(null, bouncerLogout);
  }

  async componentDidMount() {
    if (localStorage.getItem(LAST_ACTION)) {
      await this.checkTimeout();
    } else {
      localStorage.setItem(LAST_ACTION, JSON.stringify(Date.now()));
    }

    this.events.forEach(event => {
      window.addEventListener(event, this.reset);
    });

    this.interval = setInterval(async () => {
      await this.checkTimeout();
    }, CHECK_RATE);
  }

  componentWillUnmount() {
    this.cleanup();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // needed to prevent re-render when timeOutWarned flag changes, not needed
    return nextState.timeOutWarned === this.state.timeOutWarned;
  }

  reset() {
    localStorage.setItem(LAST_ACTION, JSON.stringify(Date.now()));
    if (this.state.timeOutWarned) {
      this.setState({
        timeOutWarned: false
      });
    }
  }

  cleanup() {
    clearInterval(this.interval);
    this.events.forEach(event => {
      window.removeEventListener(event, this.reset);
    });
    localStorage.removeItem(LAST_ACTION);
  }

  async checkTimeout() {
    const now = Date.now();
    const lastAction = JSON.parse(localStorage.getItem(LAST_ACTION));
    const timeLeft = lastAction + LOGOUT_TIME;
    const diff = timeLeft - now;
    const isWarningPeriod = diff < WARN_TIME;
    const isTimeout = diff < 0;

    if (!isTimeout && isWarningPeriod) {
      this.warn();
    } else if (isTimeout) {
      await this.logout();
    } else if (!lastAction) {
      await this.logout();
    }
  }

  warn() {
    if (!this.state.timeOutWarned) {
      NotificationManager.warning(INACTIVITY_LOGOUT);
      this.setState({
        timeOutWarned: true
      });
    }
  }

  async logout() {
    this.cleanup();
    console.log('Logging out due to inactivity');
    const { bouncerLogout, isAccessTokenReceived } = this.context;
    await handleLogout(false, isAccessTokenReceived, bouncerLogout);
  }

  render() {
    return (
      <React.Fragment>
        {!isEmpty(this.props?.currentUser?.accessToPages) && <RouterProvider router={memoizedRouter()} />}
      </React.Fragment>
    );
  }
}

export default Container;

const CustomTheme = {
  container: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center'
  },
  formContainer: {
    marginTop: '5%',
    marginBottom: '0'
  },
  button: {
    backgroundColor: scPurple80
  }
};

function getCustomTheme(style) {
  if (style) {
    CustomTheme.container.backgroundBlendMode = 'luminosity';
    CustomTheme.container.backgroundColor = style['--environment-main-color'];
  }
  return CustomTheme;
}

export function withReserveEnvironmentStyle(Component) {
  return function WrapperComponent(props) {
    const AuthenticatorComponent = withAuthenticator(
      withCurrentUserContainer(connect(mapStateToProps)(Component)),
      false,
      [<CustomSignIn />, <CustomForgotPassword />, <CustomNewPassword />, <Loading />],
      null,
      getCustomTheme(props.theme)
    );
    return <AuthenticatorComponent {...props} />;
  };
}

function mapStateToProps({ currentUser }) {
  return { currentUser };
}

function withCurrentUserContainer(Component) {
  return function WrapperComponent(props) {
    return (
      <CurrentUserContainer>
        <Component {...props} />
      </CurrentUserContainer>
    );
  };
}

export const withCurrentUserContainerConnected = Component =>
  withCurrentUserContainer(connect(mapStateToProps)(Component));
