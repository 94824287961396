import React, { Fragment } from 'react';
import { ConsoleLogger as Logger, I18n } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import { ForgotPassword } from 'aws-amplify-react';
import { UsernameAttributes } from 'aws-amplify-react/dist/Auth/common/types';
import { PhoneField } from 'aws-amplify-react/dist/Auth/PhoneField';
import cx from 'classnames';
import { isFunction } from 'lodash/lang';

import { UserApiApi } from '../../../../api';
import Input from '../../../../common/data-entry/Input';
import Button from '../../../../common/general/Button';
import { withAppInfo } from '../../../../common/hocs/withAppInfo';
import NotificationContainer from '../../../../common/notifications/NotificationContainer';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { EMAIL_SENT } from '../../../../constants/notificationMessages';
import AuthBox from '../AuthBox/AuthBox';
import PasswordStrengthMeter from '../PasswordStrengthMeter/PasswordStrengthMeter';

const verboseMessage =
  "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";
const translatedMessage = 'Invalid username';

const logger = new Logger('ForgotPassword');

class CustomForgotPassword extends ForgotPassword {
  state = {
    sendingCode: false,
    requestSent: false,
    isPasswordExpired: false,
    passwordExpiredNotificationSent: false,
    disabledSubmit: false,
    validator_12chars: false
  };

  returnToSignInAndReset() {
    this.setState({
      sendingCode: false,
      requestSent: false,
      passwordExpiredNotificationSent: false,
      disabledSubmit: false,
      delivery: null
    });
    this.changeState('signIn');
  }

  // Submit code and new password, to change password.
  submit(event) {
    const {
      validator_12chars,
      validator_upperLower,
      validator_passwords_match,
      validator_1digit,
      validator_special,
      disabledSubmit
    } = this.state;
    const passwordValid =
      validator_upperLower && validator_12chars && validator_1digit && validator_special && validator_passwords_match;

    if (disabledSubmit || !passwordValid) {
      return;
    }

    if (event) {
      event.preventDefault();
    }
    const { authData = {}, successCallback } = this.props;
    const { password } = this.inputs;
    const username = this.inputs.username || authData.username;
    const code = this.inputs.code || authData.code;

    if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.forgotPasswordSubmit(username, code, password)
      .then(data => {
        logger.debug(data);

        // set url bar to home '/' otherwise CustomSignIn will change our authState back to 'forgotPassword'
        // and re-render this component again. This would only occur if the url is a a reset password link with
        // a url that looks like '/reset-password?code=123456&username=e@mail.com'
        window.history.pushState({}, '', '/');

        // This will have a navigational effect when not logged in. Meaning it will trigger an authState change,
        // which in turn will render the sign in form as a feature of the package aws-amplify-react. However, if
        // the user is logged in this will have no effect, which is why we call successCallback below.
        this.changeState('signIn');
        this.setState({ delivery: null });

        // Begin Modified Code
        const payload = {
          userPoolId: Auth.configure().userPoolId,
          userName: username
        };
        UserApiApi.updatePasswordExpire(payload)
          .then(res => {
            // successCallback is passed in as a prop from the parent component ResetPassword
            // if user is logged in. If the user is not logged in, successCallback will
            // not be available since the parent component will be provided by the aws-amplify-react
            // package. We need successCallback because we want to redirect to '/' afterwards and
            // the parent component ResetPassword will be able to do that for us using react-router functionality.
            successCallback && isFunction(successCallback) && successCallback(username);

            // We want to reload the application just in case Bouncer is enabled and we want to use it for loging in.
            // Bouncer does not yet have the ability to reset passwords which is why we are still using Amplify here
            // for said purpose. The "best" way to get back to the Login page is to simply reload the app.
            this.props?.appInfo?.features?.auth0Enabled && window.location.reload();
          })
          .catch(err => console.error(err));
        // End Modified Code
      })
      .catch(err => {
        !!err?.message && NotificationManager.error(err.message);
        this.setState({ disabledSubmit: true });
      });
  }

  validator12Char() {
    const { password } = this.inputs;
    if (password && password.length >= 12) {
      this.setState({
        validator_12chars: true
      });
    } else {
      this.setState({
        validator_12chars: false
      });
    }
  }

  validator1Digit() {
    const { password } = this.inputs;
    const regex = new RegExp(/\d/);
    if (password) {
      this.setState({
        validator_1digit: regex.test(password)
      });
    } else {
      this.setState({
        validator_1digit: false
      });
    }
  }

  validatorUpperAndLower() {
    const { password } = this.inputs;
    const regex = new RegExp(/(?=.*[a-z])(?=.*[A-Z])/);
    if (password) {
      this.setState({
        validator_upperLower: regex.test(password)
      });
    } else {
      this.setState({
        validator_upperLower: false
      });
    }
  }

  validatorSpecialChar() {
    const { password } = this.inputs;
    const regex = new RegExp(/(?=.*\W)/);
    if (password) {
      this.setState({
        validator_special: regex.test(password)
      });
    } else {
      this.setState({
        validator_special: false
      });
    }
  }

  validatorPasswordsMatch() {
    const { password, confirmPassword } = this.inputs;
    if (password === confirmPassword && password.length && confirmPassword.length) {
      this.setState({
        validator_passwords_match: true
      });
    } else {
      this.setState({
        validator_passwords_match: false
      });
    }
  }

  // Send a request to reset password
  send() {
    const { authData = {} } = this.props;
    const username = this.getUsernameFromInput() || authData.username;
    if (!Auth || typeof Auth.forgotPassword !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    // by clientMetaInfo reason field we control which type of email should be sent when user reset password'
    const clientMetaData = authData.isPasswordExpired ? { reason: 'Password has expired' } : {};
    Auth.forgotPassword(username.trim().toLowerCase(), clientMetaData)
      .then(data => {
        logger.debug(data);
        this.setState({
          delivery: data.CodeDeliveryDetails,
          requestSent: true
        });
        NotificationManager.success(EMAIL_SENT);
      })
      .catch(err => {
        let errorMessage;
        if (!!err?.message && err?.message === verboseMessage) {
          errorMessage = translatedMessage;
        } else {
          errorMessage = err.message;
        }
        NotificationManager.error(errorMessage);
      });
  }

  sendCodeWithDisable = event => {
    if (event) {
      event.preventDefault();
    }

    try {
      // disable Resend Code button
      this.setState({ sendingCode: true });

      // make api request to send code
      this.send();
    } finally {
      // re-enable Resend Code button after 5 seconds
      setTimeout(() => this.setState({ sendingCode: false }), 5000);
    }
  };

  showComponent(theme) {
    const { props, state } = this;
    const { hide, authData = {} } = props;
    if (hide && hide.includes(CustomForgotPassword)) {
      return null;
    }
    const {
      requestSent,
      sendingCode,
      delivery,
      validator_12chars,
      validator_upperLower,
      validator_passwords_match,
      validator_1digit,
      validator_special,
      disabledSubmit
    } = state;

    const headerAppend = !!authData?.username && <b> for {authData.username}</b>;

    if (requestSent || authData.passwordExpiredNotificationSent) {
      return (
        <Fragment>
          <div className="auth-box">
            <form onSubmit={this.sendCodeWithDisable}>
              <div className="auth-box-header">
                {authData.passwordExpiredNotificationSent
                  ? I18n.get(`Password expired`)
                  : I18n.get(`Password reset request sent`)}
                {headerAppend}
              </div>
              <div className="auth-box-message">
                An email has been sent to the email address specified. Please check your email and follow the
                instructions.
              </div>
              <div className="auth-box-buttons custom-forgot-password-buttons">
                <Button type="button" priority="low" onClick={() => this.returnToSignInAndReset()}>
                  {I18n.get('Return to Sign In Page')}
                </Button>
                <Button type="submit" priority="low" disabled={sendingCode}>
                  {I18n.get('Resend Email')}
                </Button>
              </div>
            </form>
          </div>
          <NotificationContainer />
        </Fragment>
      );
    }

    if (delivery || authData.username) {
      const passwordValid =
        validator_upperLower && validator_12chars && validator_1digit && validator_special && validator_passwords_match;

      return (
        <Fragment>
          <div className="auth-box">
            <form onSubmit={this.submit}>
              <div className="auth-box-header">
                {I18n.get(`Reset Password`)}
                {headerAppend}
              </div>
              <div className="auth-box-fields">
                <div className={cx({ 'd-none': !!authData?.code })}>
                  <Input
                    required
                    disabled={!!authData?.code}
                    defaultValue={authData.code}
                    label={I18n.get('Code')}
                    key="code"
                    name="code"
                    autoComplete="one-time-code"
                    onChange={this.handleInputChange}
                  />
                </div>
                <Input.Password
                  autoFocus
                  required
                  label={I18n.get('New Password')}
                  key="password"
                  name="password"
                  autoComplete="new-password"
                  onChange={event => {
                    this.handleInputChange(event);
                    this.validator12Char();
                    this.validator1Digit();
                    this.validatorUpperAndLower();
                    this.validatorSpecialChar();
                    this.validatorPasswordsMatch();
                  }}
                />
                <Input.Password
                  required
                  label={I18n.get('Confirm Password')}
                  key="confirmPassword"
                  name="confirmPassword"
                  autoComplete="new-password"
                  onChange={event => {
                    this.handleInputChange(event);
                    this.validatorPasswordsMatch();
                  }}
                />
              </div>
              <PasswordStrengthMeter
                validator_12chars={validator_12chars}
                validator_upperLower={validator_upperLower}
                validator_passwords_match={validator_passwords_match}
                validator_1digit={validator_1digit}
                validator_special={validator_special}
              />
              <div className="auth-box-buttons custom-forgot-password-buttons">
                <Button type="submit" size="h56" disabled={disabledSubmit || !passwordValid}>
                  {I18n.get('Submit')}
                </Button>
              </div>
            </form>
          </div>
          <NotificationContainer />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <AuthBox>
          <form onSubmit={this.sendCodeWithDisable}>
            <div className="auth-box-header">
              {I18n.get(`Reset your password`)}
              {headerAppend}
            </div>
            <div className="auth-box-fields">{this.renderUsernameField()}</div>
            <div className="auth-box-buttons custom-forgot-password-buttons">
              <Button type="button" size="h56" priority="medium" onClick={() => this.returnToSignInAndReset()}>
                {I18n.get('Cancel')}
              </Button>
              <Button type="submit" size="h56">
                {I18n.get('Reset Password')}
              </Button>
            </div>
          </form>
        </AuthBox>
        <NotificationContainer />
      </Fragment>
    );
  }

  renderUsernameField() {
    const { usernameAttributes = [] } = this.props;
    if (usernameAttributes === UsernameAttributes.EMAIL) {
      return (
        <Input
          autoFocus
          label={I18n.get('Email')}
          placeholder={I18n.get('Enter your email')}
          key="email"
          name="email"
          onChange={this.handleInputChange}
        />
      );
    }

    if (usernameAttributes === UsernameAttributes.PHONE_NUMBER) {
      /*TODO: Do we use it? Need to check */
      return <PhoneField autoFocus onChangeText={this.onPhoneNumberChanged} />;
    }

    return (
      <Input
        autoFocus
        required
        defaultValue={this.state.username}
        label={I18n.get(this.getUsernameLabel())}
        placeholder={I18n.get('Enter your username')}
        key="username"
        name="username"
        onChange={this.handleInputChange}
      />
    );
  }
}

const CustomForgotPasswordWithAppInfo = withAppInfo(CustomForgotPassword);
export { CustomForgotPasswordWithAppInfo as CustomForgotPassword };
