import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useBouncer } from 'bouncer';
import cx from 'classnames';
import { get } from 'lodash/object';

import { getUserPreferences } from '../../../../../../actions/userPreferencesAction';
import useAppInfo from '../../../../../../common/hooks/useAppInfo';
import useOutsideClickDetector from '../../../../../../common/hooks/useOutsideClickDetector';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import {
  CALENDAR_INVITATIONS_PREFERENCES,
  HOME_PAGE_DASHBOARDS,
  NOTIFICATION_PREFERENCES
} from '../../../../../../constants/accessToPages';
import { CURRENT_ROLE } from '../../../../../../constants/notificationMessages';
import { handleLogout } from '../../../../../../services/auth';
import { getTittleByRole } from '../../../../../../services/personnelService';
import { selectRole } from '../../../../../../services/userRoleService';
import { generateUrlByKey } from '../../../../router';
import { useCurrentUser } from '../../../CurrentUserContainer';

import './User.scss';

export default function User() {
  const [showDialog, setShowDialog] = useState(false);
  const currentUser = useCurrentUser();
  const userPreferencesContainer = useRef(null);
  const appInfo = useAppInfo();
  const dispatch = useDispatch();
  const { isAccessTokenReceived, bouncerLogout } = useBouncer();

  const { activeRole, roles, firstName, lastName } = currentUser,
    userFullName = firstName + ' ' + lastName,
    userHasMoreThanOneRole = roles.length > 1;

  useEffect(() => {
    dispatch(getUserPreferences());
  }, [dispatch]);

  useEffect(function() {
    if (roles.length < 2) {
      return;
    }
    NotificationManager.success(`${CURRENT_ROLE} ${getTittleByRole(activeRole)}`, 'user-role-notification');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOutsideClickDetector(userPreferencesContainer, () => setShowDialog(false));

  return (
    <div
      className={cx('user-section', { active: showDialog })}
      ref={userPreferencesContainer}
      data-testid={'currenUserWrapper'}
    >
      <div className="user-description" onClick={() => setShowDialog(!showDialog)} data-testid={'selectedUser'}>
        <span className="user-description-active-role">{getTittleByRole(activeRole)}</span>
        <span className="user-description-full-name">{userFullName}</span>
      </div>
      <div className="user-dialog" data-testid={'user-settings'}>
        {userHasMoreThanOneRole && (
          <div className="user-dialog-roles">
            <div className="user-dialog-roles-title">ROLES</div>
            <div className="user-dialog-roles-list">
              {roles
                .map(role => {
                  return { id: role, title: getTittleByRole(role) };
                })
                .sort((role1, role2) => {
                  return role1.title.toLowerCase() > role2.title.toLowerCase() ? 1 : -1;
                })
                .map(role => (
                  <div
                    onClick={() => selectRole(role.id)}
                    className={cx('user-dialog-roles-item', {
                      'user-dialog-roles-item-selected': activeRole === role.id
                    })}
                    key={role.id}
                  >
                    {role.title}
                  </div>
                ))}
            </div>
          </div>
        )}
        {(currentUser.accessToPages.includes(CALENDAR_INVITATIONS_PREFERENCES) ||
          (get(appInfo, 'features.userNotificationsEnabled', false) &&
            currentUser.accessToPages.includes(NOTIFICATION_PREFERENCES)) ||
          currentUser.accessToPages.includes(HOME_PAGE_DASHBOARDS)) && (
          <Link
            to={generateUrlByKey('Preferences')}
            onClick={() => setShowDialog(!showDialog)}
            className="link user-dialog-btn"
          >
            Preferences
          </Link>
        )}

        <div onClick={() => handleLogout(false, isAccessTokenReceived, bouncerLogout)} className="user-dialog-btn">
          Logout
        </div>
      </div>
    </div>
  );
}
