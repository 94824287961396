import React from 'react';

import AccessDeniedTooltip from '../../../../../AccessDeniedTooltip/AccessDeniedTooltip';
import WorklistButtonOrLink from '../../WorklistButtonOrLink';
import { isAccessRestricted } from '../../worklistService';

export const ItemGroupCell = ({ row, accessor }) => {
  const getTemplateByElementState = (el, row, accessRestricted) => {
    return <WorklistButtonOrLink studySiteStatus={row.studySiteStatus} {...el} disabled={accessRestricted} />;
  };

  const disabled = isAccessRestricted(row[accessor]);
  if (disabled) {
    return (
      <AccessDeniedTooltip>
        <div className="work-list-item-group-cell">
          {row[accessor] ? getTemplateByElementState(row[accessor], row, disabled) : null}
        </div>
      </AccessDeniedTooltip>
    );
  } else {
    return (
      <div className="work-list-item-group-cell">
        {row[accessor] ? getTemplateByElementState(row[accessor], row, disabled) : null}
      </div>
    );
  }
};
