import React, { useMemo, useState } from 'react';
import isFunction from 'lodash/isFunction';
import { isEmpty } from 'lodash/lang';

import Input from '../../../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import Icon from '../../../../../../../common/general/Icon';
import useAppInfo from '../../../../../../../common/hooks/useAppInfo';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { scBlack, scGreen100 } from '../../../../../../../constants/systemColors';
import { useCurrentUser } from '../../../../../../root/Container/CurrentUserContainer';
import { authenticateAuth0User } from '../../../ReviewDEV/ReviewControl/SmPiReviewSignModal/authApi';
import { authenticateCognitoUser } from '../../../ReviewDEV/ReviewControl/SmPiReviewSignModal/cognitoService';

import '../../../../shared/EncounterDescNew/item-group/CertifyCopySignModal/CertifyCopySignModal.scss';

const bodyTextForMultipleCertifyCopyFiles =
  'By saving this record, I certify the below files are exact copies. They have all the same information and attributes as the originals.';
const bodyTextForOneCertifyCopyFile =
  'By saving this record, I certify the below file is an exact copy. It has all the same information and attributes as the original.';
const userAuthenticationErrorMessage =
  'The username or password is invalid. Please ensure the credentials match the logged in user and try again.';

export default function CertifyCopyModal({ modalBox, filesForCertify, onSave }) {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const currentUser = useCurrentUser();
  const appInfo = useAppInfo();

  const allCertifyCopyFiles = useMemo(
    function() {
      return filesForCertify.filter(file => file.isCertifyCopy);
    },
    [filesForCertify]
  );
  const allNotCertifyCopyFiles = useMemo(
    function() {
      return filesForCertify.filter(file => !file.isCertifyCopy);
    },
    [filesForCertify]
  );

  return (
    <>
      <ModalBoxes.Header>Certify Copy</ModalBoxes.Header>
      <ModalBoxes.Body>
        <p>{allCertifyCopyFiles.length === 1 ? bodyTextForOneCertifyCopyFile : bodyTextForMultipleCertifyCopyFiles}</p>
        <div className="ccsm-certify-copy-files-box">
          {allCertifyCopyFiles.length > 0 && (
            <div className="ccsm-certify-copy-files">
              <p className="ccsm-certified-label">Certified:</p>
              {allCertifyCopyFiles.map(file => (
                <div key={file.id} className="ccsm-certify-copy-file">
                  <Icon className="ccsm-file-upload-icon" suit="material" style={{ color: scGreen100 }}>
                    task
                  </Icon>
                  <p className="ccsm-upload-file-name">{file.name}</p>
                </div>
              ))}
            </div>
          )}
          {allNotCertifyCopyFiles.length > 0 && (
            <div className="ccsm-certify-copy-files">
              <p className="ccsm-certified-label">Not Certified:</p>
              {allNotCertifyCopyFiles.map(file => (
                <div className="ccsm-certify-copy-file">
                  <Icon className="ccsm-file-upload-icon" suit="material-outline" style={{ color: scBlack }}>
                    insert_drive_file
                  </Icon>
                  <p className="ccsm-upload-file-name">{file.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        <form id="signForm" onSubmit={handleSubmit}>
          <Input
            label="Username"
            name="username"
            autoFocus
            onChange={o => setEmail(o.target.value)}
            value={email}
            placeholder="Enter email"
            required
          />
          <Input
            label="Password"
            type="password"
            onChange={o => setPassword(o.target.value)}
            value={password}
            placeholder="Password"
            required
          />
        </form>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button form="signForm" loading={loader} disabled={isEmpty(email) || isEmpty(password)}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onFailure() {
    NotificationManager.error(userAuthenticationErrorMessage);
    setLoader(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (loader) {
      return;
    }
    setLoader(true);
    if (!doesInputEmailEqualsWithLoginUserEmail()) {
      NotificationManager.error(userAuthenticationErrorMessage);
      setLoader(false);
    } else {
      const callBack = {
        onSuccess: () => {
          isFunction(onSave) && onSave();
          modalBox.close();
        },
        onAuth0Success: () => {
          isFunction(onSave) && onSave();
          modalBox.close();
        },
        onFailure: onFailure
      };
      if (appInfo?.features?.auth0SignOffEnabled) {
        authenticateAuth0User(email.trim().toLowerCase(), password, callBack);
      } else {
        authenticateCognitoUser(email.trim().toLowerCase(), password, callBack);
      }
    }
  }

  function doesInputEmailEqualsWithLoginUserEmail() {
    return currentUser.email?.toLowerCase() === email?.toLowerCase();
  }
}

CertifyCopyModal.className = 'eds-sign-certify-copy-modal-box';
CertifyCopyModal.size = 'w650';
