import React from 'react';

import Input from '../../../../../../../../common/data-entry/Input';
import Radio from '../../../../../../../../common/data-entry/InputSelectors/Radio';
import Select from '../../../../../../../../common/data-entry/Select';
import Toggle from '../../../../../../../../common/data-entry/Toggle';
import {
  canadianProvinces,
  countries,
  getCountryById,
  getRegionById,
  usStates
} from '../../../../../../../../services/geographic';

export default function PatientContactDetails({
  handleCountryChange,
  handleStateChange,
  handleInputChange,
  handlePreferredContactMethodChange,
  handleSelectedPhoneTypeChange,
  isRequiredField,
  getValidationMessage,
  address,
  info,
  fieldsDataDictionary,
  handleChangeSmsOptInDropdown,
  handleChangeInterestedInFutureResearch
}) {
  const isUS = address.country === 'US';
  const regions = isUS ? usStates : canadianProvinces;
  const dropDownOptions = [
    { id: 'YES', name: 'Yes' },
    { id: 'NO', name: 'No' },
    { id: 'NOT_ASKED', name: 'Not Asked' }
  ];

  const { phonesInfo, email, interestedInFutureResearch, smsOptIn } = info;

  return (
    <div className="row">
      <div className="col-12 apm-form-label">Patient Contact Details</div>
      <div className="col-12 d-flex">
        <div className="col-4">
          <Toggle name="phonesInfo.neverCallAgain" checked={phonesInfo.neverCallAgain} onChange={handleInputChange}>
            Never Call Again
          </Toggle>
        </div>

        <div className="col-4">
          <Toggle
            name="phonesInfo.voicemailNotAllowed"
            checked={phonesInfo.voicemailNotAllowed}
            onChange={handleInputChange}
          >
            Voicemail Not Allowed
          </Toggle>
        </div>
      </div>
      <div className="col-4">
        <Select
          name="smsOptIn"
          label="Sms Opt-In"
          dataSource={dropDownOptions}
          value={findDropDownOption(smsOptIn) || null}
          onChange={handleChangeSmsOptInDropdown}
          clearable={false}
        />
      </div>
      <div className="col-4">
        <Select
          name="interestedInFutureResearch"
          label="Interested in Future Research"
          dataSource={dropDownOptions}
          value={findDropDownOption(interestedInFutureResearch) || null}
          onChange={handleChangeInterestedInFutureResearch}
          clearable={false}
        />
      </div>
      <div className="col-12" />
      <div className="col-4">
        <Input
          label="Phone (primary)"
          name="phonesInfo.primary"
          value={phonesInfo.primary}
          onChange={handleInputChange}
          validationMessage={getValidationMessage('phonesInfo.primary')}
          required={isRequiredField('phonesInfo.primary')}
        />
        <Radio.Group
          name="phonesInfo.primaryPhoneType"
          checkedValue={phonesInfo.primaryPhoneType}
          options={fieldsDataDictionary?.phoneTypeCodes}
          onChange={value => handleSelectedPhoneTypeChange(value, 'primaryPhoneType')}
          optionLabelKey="name"
          optionValueKey="id"
        />
      </div>
      <div className="col-4">
        <Input
          label="Phone (alternate)"
          name="phonesInfo.alternative"
          value={phonesInfo.alternative}
          onChange={handleInputChange}
          validationMessage={getValidationMessage('phonesInfo.alternative')}
        />
        <Radio.Group
          name="phonesInfo.alternativePhoneType"
          checkedValue={phonesInfo.alternativePhoneType}
          options={fieldsDataDictionary?.phoneTypeCodes}
          onChange={value => handleSelectedPhoneTypeChange(value, 'alternativePhoneType')}
          optionLabelKey="name"
          optionValueKey="id"
        />
      </div>
      <div className="col-4">
        <Input
          label="Email"
          name="email"
          value={email}
          onChange={handleInputChange}
          validationMessage={getValidationMessage('email')}
        />
      </div>
      <div className="col-4 mt-2">
        <Select
          label="Preferred Contact Method"
          name="preferredContactMethod"
          value={fieldsDataDictionary?.contactCodes?.find(({ id }) => id === phonesInfo.preferredContactMethod) || null}
          onChange={handlePreferredContactMethodChange}
          deselectOnSelectedOptionClick={false}
          dataSource={fieldsDataDictionary?.contactCodes}
        />
      </div>
      <div className="col-9"></div>
      <div className="col-4">
        <Select
          label="Country"
          name="address.country"
          value={address.country ? getCountryById(address.country) : null}
          onChange={handleCountryChange}
          required={isRequiredField('address.country')}
          validationMessage={getValidationMessage('address.country')}
          clearable={false}
          deselectOnSelectedOptionClick={false}
          dataSource={countries}
        />
      </div>
      <div className="col-4">
        <Input
          label="Address 1"
          name="address.addressLine1"
          value={address.addressLine1}
          onChange={handleInputChange}
          required={isRequiredField('address.addressLine1')}
          validationMessage={getValidationMessage('address.addressLine1')}
        />
      </div>
      <div className="col-4">
        <Input
          label="Address 2"
          name="address.addressLine2"
          value={address.addressLine2}
          onChange={handleInputChange}
        />
      </div>
      <div className="col-4">
        <Input
          label="City"
          name="address.city"
          value={address.city}
          onChange={handleInputChange}
          required={isRequiredField('address.city')}
          validationMessage={getValidationMessage('address.city')}
        />
      </div>
      <div className="col-4">
        <Select
          label={isUS ? 'State' : 'Province'}
          clearSearchOnSelection={false}
          searchable
          clearable={false}
          closeOnSelectedOptionClick={false}
          deselectOnSelectedOptionClick={false}
          onChange={v => handleStateChange(v, 'address.state')}
          optionLabelKey="id"
          dataSource={regions}
          value={address.state ? getRegionById(regions, address.state) : null}
          required={isRequiredField('address.state')}
          validationMessage={getValidationMessage('address.state')}
          customSelectedValueTemplateFunction={selectedCountries => countryLabel(selectedCountries[0])}
          customOptionTemplateFunction={countryLabel}
        />
      </div>
      <div className="col-4">
        <Input
          label={isUS ? 'ZIP' : 'Postal Code'}
          name="address.zipCode"
          value={address.zipCode}
          onChange={handleInputChange}
          required={isRequiredField('address.zipCode')}
          validationMessage={getValidationMessage('address.zipCode')}
        />
      </div>
    </div>
  );
  function findDropDownOption(optionId) {
    return dropDownOptions.find(({ id }) => id === optionId);
  }
}

function countryLabel({ name, id } = {}) {
  return (
    <React.Fragment>
      {id} <span className="country-description">({name})</span>
    </React.Fragment>
  );
}
