import React, { useEffect, useState } from 'react';
import * as moment from 'moment/moment';

import { PatientInfoApi, StudyApi } from '../../../../../../../api';
import ModalBoxes, { useModalBoxes } from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { onRequestError } from '../../../../../../../services/handlers';

import { prepareDataForSave, preparePatientInfoForAssign } from './AddOrEditPatientInfoModalService';

export default function DuplicatePatientModal({
  modalBox,
  currentStudy,
  patientInfo,
  duplicateInfo,
  existsInStudy,
  onSave
}) {
  const { setLoading } = useModalBoxes();
  const [duplicatedOn, setDuplicatedOn] = useState(null);
  const { studyName } = currentStudy;
  const patientIdsByDOB = duplicateInfo.dob?.map(info => info.patientId).join(', ');
  const patientIdsByEmail = duplicateInfo.email?.map(info => info.patientId).join(', ');
  const patientIdsByPhone = duplicateInfo.phone?.map(info => info.patientId).join(', ');

  useEffect(() => {
    if (duplicateInfo.dob) {
      setDuplicatedOn('dob');
    } else if (duplicateInfo.email) {
      setDuplicatedOn('email');
    } else if (duplicateInfo.phone) {
      setDuplicatedOn('phone');
    }
  }, [duplicateInfo]);

  const formattedDob = dob => {
    return moment(dob).format('DD/MMM/YYYY');
  };

  function close(existsInStudy, patient) {
    if (existsInStudy) {
      modalBox.close();
    } else {
      const name = `${patient.firstName} ${patient.lastName}`;
      NotificationManager.error(name + ' was not added to the ' + studyName + ' study');
    }
  }

  function addNewStudyForPatient(data, currentStudy) {
    const name = `${data.firstName} ${data.lastName}`;
    const patientData = preparePatientInfoForAssign(data, currentStudy);
    StudyApi.addPatientStudy(patientData)
      .then(() => {
        NotificationManager.success(name + ' has been added to the ' + currentStudy.studyName + ' study');
      })
      .catch(onRequestError)
      .finally(() => {
        modalBox.close();
      });
  }

  function updatePatient() {
    // fix this unnecessary reassignment
    const info = patientInfo;
    info.dob = formattedDob(info.dob);
    PatientInfoApi.updatePatientInfo(info.id, prepareDataForSave(info))
      .then(function({ data: { pidMessage, warningMessage } }) {
        onSave(info);
        if (pidMessage !== '') {
          warningMessage ? NotificationManager.error(pidMessage) : NotificationManager.success(pidMessage);
        } else if (warningMessage) {
          NotificationManager.warning(warningMessage);
        }
      })
      .catch(function(err, params) {
        onRequestError(err, params);
        setLoading(false);
      });
  }

  return (
    <>
      <ModalBoxes.Body>
        {existsInStudy && duplicatedOn === 'dob' && (
          <p>
            A patient named {duplicateInfo.dob[0].firstName} {duplicateInfo.dob[0].lastName} and matching date of birth{' '}
            {formattedDob(duplicateInfo.dob[0].dob)} and having patient ID {duplicateInfo.dob[0].patientId} is already
            assigned to the study {studyName}
          </p>
        )}
        {!existsInStudy && duplicatedOn === 'dob' && (
          <div>
            <p>
              A patient named {duplicateInfo.dob[0].firstName} {duplicateInfo.dob[0].lastName} and matching date of
              birth {formattedDob(duplicateInfo.dob[0].dob)} and having patient{' '}
              {duplicateInfo.dob.length > 1
                ? `ID's ${patientIdsByDOB} were `
                : `ID ${duplicateInfo.dob[0].patientId} was `}
              found. <br />
              <br /> Do you want to assign patient {duplicateInfo.dob[0].patientId} to the study {studyName}?
            </p>
          </div>
        )}
        {!existsInStudy && duplicatedOn === 'email' && (
          <p>
            A patient named {duplicateInfo.email[0].firstName} {duplicateInfo.email[0].lastName} matching email and
            having patient ID's {patientIdsByEmail} was found. <br />
            <br />
            Are you sure you want to save the changes?
          </p>
        )}
        {!existsInStudy && duplicatedOn === 'phone' && (
          <p>
            A patient named {duplicateInfo.phone[0].firstName} {duplicateInfo.phone[0].lastName} matching phone number{' '}
            {duplicateInfo.phone[0].contactNumber} and having patient{' '}
            {duplicateInfo.phone.length > 1 ? `ID's ${patientIdsByPhone} were` : `ID ${patientIdsByPhone} was`} found.
            <br />
            <br />
            Are you sure you want to save the changes?
          </p>
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={close}>
            Cancel
          </Button>
          {!existsInStudy && duplicatedOn === 'dob' && (
            <Button
              onClick={() => {
                const data = duplicateInfo.dob[0];
                addNewStudyForPatient(data, currentStudy);
              }}
            >
              Assign
            </Button>
          )}
          {!existsInStudy && duplicatedOn === 'email' && <Button onClick={updatePatient}>Yes, I am sure</Button>}
          {!existsInStudy && duplicatedOn === 'phone' && <Button onClick={updatePatient}>Yes, I am sure</Button>}
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

DuplicatePatientModal.className = 'duplicate-patient-info-modal';
DuplicatePatientModal.size = 'w650';
