import { Auth } from 'aws-amplify';
import axios from 'axios';
import jstz from 'jstz';

import cognitoConfig from '../constants/cognitoConfig';
import store from '../store';

import NewRelic from './NewRelic';

const timezone = jstz.determine();

const addForbiddenResponseInterceptor = isAuth0 => {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    function(error) {
      if (error.response?.status === 403) {
        if (
          /^Forbidden [A-Z0-9_]*$/.test(error.response?.data?.message) &&
          !isSmPiReviewSignForbidden(error) &&
          !isTaskViewForbidden(error)
        ) {
          if (!isAuth0) {
            // if using Amplify and not bouncer
            redirectToLandingPage();
          }
        }
      }
      return Promise.reject(error);
    }
  );
};

axios.interceptors.request.use(function(config) {
  const { activeRole } = store.getState().currentUser;
  if (activeRole) {
    config.headers['X-Active-Role'] = activeRole;
  }
  config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  config.headers.common['Client-Timezone'] = timezone.name();
  return config;
});

const addAccessTokenInterceptor = getAccessToken => {
  axios.interceptors.request.use(async config => {
    const token = await getAccessToken();
    if (config?.headers?.common) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }
    NewRelic.start();
    return config;
  });
};

const addCognitoAccessTokenInterceptor = () => {
  axios.interceptors.request.use(async config => {
    await cognitoConfig();
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    if (config?.headers?.common) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }
    NewRelic.start();
    return config;
  });
};

axios.interceptors.response.use(
  async response => {
    NewRelic.end();
    return response;
  },
  function(error) {
    NewRelic.end();
    NewRelic.noticeError(error);
    throw error;
  }
);

function redirectToLandingPage() {
  window.location.href = '/';
}

function isSmPiReviewSignForbidden(error) {
  const errorMessage = error.response?.data?.message;
  return /^Forbidden SIGN_SM_REVIEW*$/.test(errorMessage) || /^Forbidden SIGN_PI_REVIEW*$/.test(errorMessage);
}

function isTaskViewForbidden(error) {
  const errorMessage = error.response?.data?.message;
  return /^Forbidden VIEW_TASKS*$/.test(errorMessage);
}

export { addAccessTokenInterceptor, addCognitoAccessTokenInterceptor, addForbiddenResponseInterceptor };
