import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { DateTimePicker } from '@mui/x-date-pickers';
import { isNull } from 'lodash/lang';
import moment from 'moment';

import OutreachCallbackApi from '../../../../../../api/patient/OutreachCallbackApi';
import OutreachContactAttemptApi from '../../../../../../api/patient/OutreachContactAttemptApi';
import Toggle from '../../../../../../common/data-entry/Toggle';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { onRequestDefaultError, onRequestError } from '../../../../../../services/handlers';
import store from '../../../../../../store';

import './OutreachAttemptTrackingModal.scss';

export const OutreachAttemptTrackingModal = ({ modalBox, ssuPatientId, viewOnly }) => {
  const [outreachAttempts, setOutreachAttempts] = useState([]);
  const [callbackId, setCallbackId] = useState(null);
  const [callbackRequested, setCallbackRequested] = useState(false);
  const [callbackDateTime, setCallbackDateTime] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    OutreachCallbackApi.getCallback(ssuPatientId)
      .then(({ data }) => {
        setCallbackId(data.id);
        setCallbackRequested(data.callbackRequested);
        if (!isNull(data.callbackDateTime)) {
          setCallbackDateTime(moment(data.callbackDateTime));
        }
      })
      .catch(onRequestError);

    OutreachContactAttemptApi.getAttempts(ssuPatientId)
      .then(({ data }) => {
        setOutreachAttempts(data);
        setLoading(false);
      })
      .catch(onRequestError);
  }, [ssuPatientId]);

  const onSave = useCallback(() => {
    const outreachCallback = {
      id: callbackId,
      sitePatientId: ssuPatientId,
      callbackRequested: callbackRequested,
      callbackDateTime: callbackRequested ? callbackDateTime : null
    };
    OutreachCallbackApi.saveCallback(ssuPatientId, outreachCallback).catch(onRequestError);

    OutreachContactAttemptApi.saveAttempts(ssuPatientId, outreachAttempts)
      .then(() => {
        modalBox.close();
        NotificationManager.success('Contact Attempts successfully saved');
      })
      .catch(onRequestDefaultError);
  }, [callbackId, ssuPatientId, callbackRequested, callbackDateTime, outreachAttempts, modalBox]);

  const addNewAttempt = useCallback(() => {
    const currentUser = store.getState().currentUser;
    const newAttempt = {
      id: null,
      sitePatientId: ssuPatientId,
      attemptNumber: outreachAttempts.length + 1,
      attemptDate: moment(),
      userName: currentUser.firstName + ' ' + currentUser.lastName,
      attemptedBy: currentUser.personnelIdentifier,
      attemptMethodText: false,
      attemptMethodEmail: false,
      attemptMethodPhone: false,
      contactNote: ''
    };
    setOutreachAttempts([...outreachAttempts, newAttempt]);
  }, [outreachAttempts, ssuPatientId]);

  const updateRowField = useCallback((attemptNumber, fieldName, value) => {
    setOutreachAttempts(prevState =>
      prevState.map(row => (row.attemptNumber === attemptNumber ? { ...row, [fieldName]: value } : row))
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'attemptNumber',
        headerName: 'Attempt #',
        type: 'number',
        minWidth: 100
      },
      {
        field: 'attemptDate',
        headerName: 'Attempt Date',
        minWidth: 125,
        renderCell: ({ row: { attemptDate } }) => (
          <div>{attemptDate ? moment.utc(attemptDate).format(DD_SLASH_MMM_SLASH_YYYY) : ''}</div>
        )
      },
      {
        field: 'userName',
        headerName: 'Attempted By',
        minWidth: 125
      },
      {
        field: 'attemptedMethodText',
        headerName: 'Attempt Method',
        minWidth: 300,
        renderCell: ({ row }) => <AttemptMethodCheckboxes row={row} updateRowField={updateRowField} />
      },
      {
        field: 'contactNote',
        headerName: 'Contact Note',
        minWidth: 350,
        renderCell: ({ row }) => <RowInput row={row} updateRowField={updateRowField} field="contactNote" />
      }
    ],
    [updateRowField]
  );

  return (
    <>
      <ModalBoxes.Body>
        <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="10px">
          <Box display="flex" alignItems="center">
            <Toggle
              checked={callbackRequested}
              onChange={e => setCallbackRequested(e.target.checked)}
              className="callback-requested-toggle"
              disabled={viewOnly}
            >
              Callback Requested
            </Toggle>
            <DateTimePicker
              label="Date and Time"
              disabled={!callbackRequested || viewOnly}
              value={callbackRequested ? callbackDateTime : null}
              onChange={value => setCallbackDateTime(value)}
              slotProps={{
                textField: { variant: 'standard', required: true }
              }}
            />
          </Box>
          {!viewOnly && (
            <Button onClick={addNewAttempt} data-testid="outreach-attempt-tracking-new-attempt-button">
              + New Attempt
            </Button>
          )}
        </Box>
        <DataGridPremium
          data-testid="outreach-attempt-trackin-table"
          sx={{
            height: '500px',
            border: 'none'
          }}
          loading={loading}
          columns={columns}
          rows={outreachAttempts}
          getRowId={row => row.attemptNumber}
          localeText={{ noRowsLabel: 'No Record Found' }}
          rowHeight={38}
          disableRowSelectionOnClick
          disableRowGrouping
          disableAggregation
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        {viewOnly ? (
          <Button onClick={modalBox.close}>Close</Button>
        ) : (
          <ButtonGroup>
            <Button priority="low" onClick={modalBox.close}>
              Cancel
            </Button>
            <Button
              onClick={onSave}
              data-testid="outreach-attempt-tracking-save-button"
              disabled={callbackRequested && !callbackDateTime?.isValid()}
            >
              Save
            </Button>
          </ButtonGroup>
        )}
      </ModalBoxes.Footer>
    </>
  );
};

const AttemptMethodCheckboxes = ({ row, updateRowField }) => {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={row.attemptMethodText}
            onChange={({ target: { checked } }) => updateRowField(row.attemptNumber, 'attemptMethodText', checked)}
            disabled={row.id !== null}
          />
        }
        label="Text"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={row.attemptMethodEmail}
            onChange={({ target: { checked } }) => updateRowField(row.attemptNumber, 'attemptMethodEmail', checked)}
            disabled={row.id !== null}
          />
        }
        label="Email"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={row.attemptMethodPhone}
            onChange={({ target: { checked } }) => updateRowField(row.attemptNumber, 'attemptMethodPhone', checked)}
            disabled={row.id !== null}
          />
        }
        label="Phone"
      />
    </div>
  );
};

const RowInput = ({ row, updateRowField, field }) => {
  const [inputValue, setInputValue] = useState(row?.[field] ?? '');
  useEffect(() => {
    setInputValue(row?.[field] ?? '');
  }, [field, row]);
  return (
    <TextField
      value={inputValue}
      onChange={({ target: { value } }) => setInputValue(value)}
      onKeyDown={event => event.stopPropagation()}
      onBlur={() => updateRowField(row.attemptNumber, field, inputValue)}
      sx={{ height: '25px', width: '100%', '.MuiInputBase-root': { height: '25px' } }}
      disabled={row.id !== null}
      title={inputValue}
      inputProps={{ maxLength: 500 }}
    />
  );
};
