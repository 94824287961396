import React from 'react';
import * as PropType from 'prop-types';

import Button from '../../../general/Button';
import ButtonGroup from '../../../general/ButtonGroup';
import ModalBoxes from '../ModalBoxes';

import './ModalBoxConfirmation.scss';

export default function ModalBoxConfirmation({ modalBox, confirm, children, cancelButton, confirmButton }) {
  return (
    <>
      <ModalBoxes.Body>{children}</ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          {cancelButton && (
            <Button priority="medium" onClick={modalBox.close}>
              {cancelButton}
            </Button>
          )}
          <Button onClick={confirm}>{confirmButton}</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

ModalBoxConfirmation.propTypes = {
  confirm: PropType.func.isRequired,
  cancelButton: PropType.oneOfType([PropType.string, PropType.bool]),
  confirmButton: PropType.oneOfType([PropType.string, PropType.bool])
};

ModalBoxConfirmation.defaultProps = {
  children: 'Are you sure?',
  cancelButton: 'Cancel',
  confirmButton: 'Confirm'
};
