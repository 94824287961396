import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-premium';
import moment from 'moment';

import { dateTime12 } from '../../../../../constants/dateFormat';
import { CLOSED } from '../../../../../constants/ssuStatuses';
import { scBlue100 } from '../../../../../constants/systemColors';
import { MANAGE_SSU_PATIENT_STATUSES, PENDING_SCHEDULE_WORK_LIST } from '../../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../../services/auth';
import { generateUrlByKey } from '../../../../root/router';
import AppointmentColumn from '../AppointmentColumn';
import PatientStatusChange from '../PatientStatusChange';
import { ScheduleAppointmentColumn } from '../ScheduleAppointmentColumn';
import { isAccessRestricted, showScheduleAppointmentPlus } from '../worklistService';

import { ItemGroupCell } from './ItemGroupCell/ItemGroupCell';
import { screeningStatuses } from './WorklistTableConstants';
import { viewAttempts } from './WorklistTableServices';

import './WorklistTableMUI.scss';

export const WorklistTableMUI = ({ tableData, itemGroupAccessors, workListFilteringData, updateStatus }) => {
  const columns = useMemo(() => {
    const startingColumns = [
      {
        headerName: 'Patient',
        field: 'fullName',
        flex: 1,
        renderCell: ({ row }) => {
          return (
            <Link
              to={generateUrlByKey('Worklist.Patient Profile.Patient Studies', {
                patientId: row.patientId,
                ssuPatientId: row.sitePatientId
              })}
            >
              {row.fullName}
              {row.preferredName && <span className="patient-preferred-name"> ({row.preferredName})</span>}
            </Link>
          );
        }
      },
      {
        headerName: 'Site',
        field: 'site',
        flex: 1,
        valueGetter: ({ row }) => row.site.name,
        renderCell: ({ row }) => row.site.name
      },
      {
        headerName: 'Ready for',
        field: 'readyFor',
        flex: 1
      },
      {
        headerName: 'Last Updated (Days)',
        field: 'idleTime',
        flex: 1,
        type: 'number',
        valueGetter: ({ value }) => (value >= 0 ? value : null),
        renderCell: ({ value }) => (value >= 0 ? value : '')
      }
    ];

    const itemGroupColumns =
      itemGroupAccessors && Object.keys(itemGroupAccessors).length > 0
        ? Object.keys(itemGroupAccessors).map(accessor => ({
            headerName: itemGroupAccessors[accessor],
            field: accessor,
            valueGetter: ({ row }) => row[accessor]?.state,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            cellClassName: ({ row }) => (isAccessRestricted(row[accessor]) ? 'item-group-access-restricted' : ''),
            renderCell: ({ row }) => {
              return <ItemGroupCell row={row} accessor={accessor} />;
            }
          }))
        : [];

    const endingColumns = [
      {
        headerName: 'Contact Attempt',
        field: 'contactAttempt',
        width: 175,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row, value }) => (
          <Button
            variant="text"
            sx={{ color: scBlue100, fontWeight: 400 }}
            onClick={() => viewAttempts(row.sitePatientId)}
          >
            {value}
          </Button>
        )
      },
      {
        headerName: 'Appointment',
        field: 'appointment.date',
        maxWidth: 170,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => (row.appointment?.date ? moment(row.appointment?.date).format(dateTime12) : null),
        renderCell: ({ row }) => (
          <AppointmentColumn
            studySiteStatus={row.studySiteStatus}
            studyId={workListFilteringData?.selectedStudyUniqueIdentifier}
            sitePatientId={row.sitePatientId}
            patientIdentifier={row.patientId}
            appointment={row.appointment}
          />
        )
      },
      {
        headerName: 'Schedule Appointments',
        maxWidth: 170,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        show: userHasAccessTo(PENDING_SCHEDULE_WORK_LIST),
        field: 'scheduleAppointment',
        renderCell: ({ row }) =>
          showScheduleAppointmentPlus(row) && (
            <ScheduleAppointmentColumn
              selectedStudySites={{ id: row.studySiteIdentifier }}
              studySiteStatus={row.studySiteStatus}
              selectedStudies={row.study}
              selectedSites={row.site}
              patient={`${row.firstName} ${row.lastName}`}
            />
          )
      },
      {
        headerName: 'Patient Status',
        field: 'status',
        valueGetter: ({ row }) => screeningStatuses.find(status => status.code === row.status)?.name,
        width: 250,
        renderCell: ({ row }) => {
          const isAllowedToEdit =
            userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
            (row.studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_SSU_PATIENT_STATUSES));
          return <PatientStatusChange row={row} isAllowedToEdit={isAllowedToEdit} updateStatus={updateStatus} />;
        }
      }
    ];

    return [...startingColumns, ...itemGroupColumns, ...endingColumns];
  }, [itemGroupAccessors, updateStatus, workListFilteringData?.selectedStudyUniqueIdentifier]);

  return (
    <DataGridPremium
      sx={{
        padding: '0 20px 0 20px',
        border: 'none'
      }}
      data-testid="worklist-table"
      getRowId={row => row?.sitePatientId}
      rows={tableData || []}
      columns={columns}
      rowHeight={38}
      localeText={{ noRowsLabel: 'No Record Found' }}
      disableRowSelectionOnClick
      disableRowGrouping
      disableAggregation
      slots={{
        toolbar: () => (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport
              printOptions={{ disableToolbarButton: true }}
              excelOptions={{ disableToolbarButton: true }}
              csvOptions={{
                escapeFormulas: false,
                utf8WithBom: true
              }}
            />
            <GridToolbarQuickFilter sx={{ margin: '0 0 0 auto' }} />
          </GridToolbarContainer>
        )
      }}
    />
  );
};
