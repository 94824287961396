import React from 'react';

import Button from '../../../../../common/general/Button';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import WorkListFilter from '../WorklistFilter';

export const WorklistHeader = ({
  toDoItemGroupsCount,
  statusCounts,
  consentedPatientsProgressCount,
  workListStatuses,
  downloadCSV
}) => {
  return (
    <PageInfoHeader
      pageInfo={
        <>
          <PageInfoHeader.AdditionalInfo title="Queue">
            {toDoItemGroupsCount?.queue} ( Chart Review: {toDoItemGroupsCount?.chartReview} , Outreach:{' '}
            {toDoItemGroupsCount?.outreach} )
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Pre-screened">
            {statusCounts?.preScreened}
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Pre-screen Failed">
            {statusCounts?.preScreenFailed}
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Consented">
            {consentedPatientsProgressCount?.enrollments}/{consentedPatientsProgressCount?.consented}
          </PageInfoHeader.AdditionalInfo>
        </>
      }
      right={
        <Button size="h28" priority="medium" onClick={downloadCSV}>
          Export
        </Button>
      }
    >
      <WorkListFilter workListStatuses={workListStatuses} />
    </PageInfoHeader>
  );
};
