import React, { useMemo } from 'react';
import moment from 'moment';

import { CLOSED } from '../../../../constants/ssuStatuses';
import { MANAGE_PATIENT_APPOINTMENTS } from '../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../services/auth';

import ScheduleAppointment from './ScheduleAppointment';

import './AppointmentColumn.scss';

export default function AppointmentColumn({
  appointment: { encounterId = null, type, date = null, warning },
  studySiteStatus,
  ...props
}) {
  const dateFormatPattern = 'DD/MMM/YYYY hh:mm A';
  const formattedDate = date ? moment(date).format(dateFormatPattern) : null;

  const isUserHaveAccessToScheduleAppointment = useMemo(() => {
    return (
      userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
      (studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_PATIENT_APPOINTMENTS))
    );
  }, [studySiteStatus]);

  const typeToMarkup = {
    ReadyToScheduleAppointment: isUserHaveAccessToScheduleAppointment && (
      <ScheduleAppointment {...props} encounterId={encounterId} />
    ),
    ScheduledAppointment: <span>{formattedDate}</span>,
    IdentifiedOrPreScreeningStatusAndReadyForScheduling: (
      <span className="invalid-patient-status-warning">{warning}</span>
    ),
    UnavailableAppointment: <span />
  };
  return typeToMarkup[type];
}
