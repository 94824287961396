import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { Config } from 'aws-sdk/global';

import cognitoConfig from '../../../../../../../constants/cognitoConfig';

export async function getUserData(email) {
  const config = await cognitoConfig();
  /**
   * This is used to point this modal to the Cognito Signature Client. The
   * new client ID was created so that the new logins would not interfere
   * with the current user login for the site.
   * */
  Config.region = config.region;
  return {
    Username: email,
    Pool: new CognitoUserPool({
      UserPoolId: config.userPoolId,
      ClientId: config.userPoolSignatureWebClientId
    })
  };
}

export function authenticateCognitoUser(email, password, callBack) {
  const authenticationDetails = getAuthenticationDetails(email, password);
  getUserData(email).then(userData => {
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, callBack);
  });
}

function getAuthenticationDetails(email, password) {
  const lowerCaseEmail = email.trim().toLowerCase();
  const trimmedPassword = password.trim();
  const authenticationData = {
    Username: lowerCaseEmail,
    Password: trimmedPassword
  };
  return new AuthenticationDetails(authenticationData);
}
