import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash/array';
import { sortBy } from 'lodash/collection';
import { isEmpty, isString } from 'lodash/lang';
import { get } from 'lodash/object';
import moment from 'moment';

import { PatientStatusApi, PreScreenApi } from '../../../../api';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { EXPORT_FAILED, PATIENT_STATUS_UPDATED } from '../../../../constants/notificationMessages';
import { onFileSave, onRequestError } from '../../../../services/handlers';
import PatientStatusChangeModal from '../../patient-source/Patients/PatientInfo/PatientInfoSidebar/PatientStatusChangeModal';

import { WorklistHeader } from './WorklistHeader/WorklistHeader';
import { screeningStatuses } from './WorklistTable/WorklistTableConstants';
import { WorklistTableMUI } from './WorklistTable/WorklistTableMUI';
import { prepareRenderData } from './worklistService';

const Worklist = props => {
  const [state, setState] = useState({
    columns: [],
    renderData: [],
    workListStatuses: [],
    selectedStudy: null,
    selectedSite: null,
    selectedWorkListStatus: null
  });

  const updateTableData = useCallback((sitePatientId, newStatus, renderData) => {
    const updatedRenderData = renderData.map(row =>
      row.sitePatientId === sitePatientId ? { ...row, status: newStatus } : row
    );
    setState(prevState => ({ ...prevState, renderData: updatedRenderData }));
  }, []);

  const updateStatus = (sitePatientId, prevStatus, newStatusCode) => {
    const initialStatus = screeningStatuses.find(e => e.code === newStatusCode);
    if (initialStatus?.isDropStatus) {
      openDropStatusModal(initialStatus, prevStatus, sitePatientId);
    } else {
      PatientStatusApi.changeStatus(sitePatientId, {
        statusCodeId: initialStatus.id,
        statusChangeLocation: 'PRE_SCREEN_WORKLIST',
        statusDate: moment().format('YYYY-MM-DD')
      }).then(() => {
        NotificationManager.success(PATIENT_STATUS_UPDATED);
        updatePage();
      }, onRequestError);
    }
  };

  const openDropStatusModal = (initialStatus, prevStatus, sitePatientId) => {
    updateTableData(sitePatientId, initialStatus.code, state.renderData);
    const patientStatusesChangeModal = ModalBoxes.open({
      component: (
        <PatientStatusChangeModal
          initialStatus={initialStatus}
          allowedStatusesPredicate={getPreScreenStatuses}
          updateStatus={(status, reason, comment) =>
            updatePreScreenStatus(sitePatientId, status, reason, comment).then(() => {
              NotificationManager.success(PATIENT_STATUS_UPDATED);
              patientStatusesChangeModal.close();
              updatePage();
            }, onRequestError)
          }
        />
      ),
      onClose: () => {
        updateTableData(sitePatientId, prevStatus, state.renderData);
      }
    });
  };

  function getPreScreenStatuses(status) {
    return screeningStatuses.map(screeningStatus => screeningStatus.id).includes(status.id);
  }

  const updatePreScreenStatus = (sitePatientId, status, reason, comment) => {
    return PatientStatusApi.changeStatus(sitePatientId, {
      statusCodeId: status?.id,
      withdrawReason: reason?.id,
      comment,
      statusChangeLocation: 'PRE_SCREEN_WORKLIST',
      statusDate: moment().format('YYYY-MM-DD')
    });
  };

  const getFilteredWorkListPatients = useCallback((allWorkListPatients, { selectedSite, selectedWorkListStatus }) => {
    const filteredBySite = filterWorkListPatientsBySite(selectedSite, allWorkListPatients);
    return filterWorkListPatientsByStatus(selectedWorkListStatus, filteredBySite);
  }, []);

  const loadWorkListPatients = useCallback(
    ({ selectedStudyUniqueIdentifier, ...siteStatusFilters }) => {
      selectedStudyUniqueIdentifier &&
        PreScreenApi.getWorkListPatients(selectedStudyUniqueIdentifier).then(res => {
          const renderData = prepareRenderData(res.data);
          const wlStatuses = generateUniqueWorkListStatuses(renderData.patients);
          setState(prevState => ({
            ...prevState,
            itemGroupAccessors: renderData.itemGroupAccessors,
            originalRenderData: renderData.patients,
            renderData: getFilteredWorkListPatients(renderData.patients, siteStatusFilters),
            workListStatuses: wlStatuses
          }));
        });
    },
    [getFilteredWorkListPatients]
  );

  useEffect(() => {
    const { workListFilteringData } = props;
    if (!isEmpty(workListFilteringData)) {
      loadWorkListPatients(workListFilteringData);
      getWorkListPatientsCounts(workListFilteringData);
    }
  }, [loadWorkListPatients, props]);

  const getWorkListPatientsCounts = res => {
    const selectedSite = res.selectedSite ? res.selectedSite.id : null;
    res.selectedStudyUniqueIdentifier &&
      PreScreenApi.getWorkListPatientsCounts(res.selectedStudyUniqueIdentifier, selectedSite).then(({ data }) => {
        setState(prevState => ({
          ...prevState,
          consentedPatientsProgressCount: data.consentedPatientsProgressCount,
          statusCounts: data.statusCounts,
          toDoItemGroupsCount: data.toDoItemGroupsCount
        }));
      });
  };

  const updatePage = () => {
    loadWorkListPatients(props?.workListFilteringData);
    getWorkListPatientsCounts(props.workListFilteringData);
  };

  const generateUniqueWorkListStatuses = renderData => {
    const workListStatuses = renderData.map(patient => {
      return { id: patient.readyFor, name: patient.readyFor };
    });
    return sortBy(sortBy(uniqBy(workListStatuses, 'id'), 'name'), e => e.name === 'Complete');
  };

  const filterWorkListPatientsBySite = (site, filteredByStudy) => {
    const name = get(site, 'name');
    if (isString(name)) {
      return filteredByStudy.filter(p => p.site.name === site.name);
    } else {
      return filteredByStudy;
    }
  };

  const filterWorkListPatientsByStatus = (workListStatus, filteredBySite) => {
    const name = get(workListStatus, 'name');
    if (isString(name)) {
      return filteredBySite.filter(p => p.readyFor === name);
    } else {
      return filteredBySite;
    }
  };

  const downloadCSV = () => {
    const { workListFilteringData } = props;
    const status = get(workListFilteringData, `selectedWorkListStatus.id`);
    const patientName = null;
    const selectedSite = get(workListFilteringData, `selectedSite.id`);
    PreScreenApi.exportPatients(workListFilteringData.selectedStudyUniqueIdentifier, selectedSite, status, patientName)
      .then(onFileSave)
      .catch(() => {
        NotificationManager.error(EXPORT_FAILED);
      });
  };

  return (
    <div className="work-list-page">
      <WorklistHeader
        toDoItemGroupsCount={state.toDoItemGroupsCount}
        statusCounts={state.statusCounts}
        consentedPatientsProgressCount={state.consentedPatientsProgressCount}
        workListStatuses={state.workListStatuses}
        downloadCSV={downloadCSV}
      />
      <WorklistTableMUI
        tableData={state.renderData}
        itemGroupAccessors={state.itemGroupAccessors}
        workListFilteringData={props.workListFilteringData}
        updateStatus={updateStatus}
      />
    </div>
  );
};

function mapStateToProps({ workListFilteringData }) {
  return { workListFilteringData };
}

export default connect(mapStateToProps, null)(Worklist);
