import { Outlet } from 'react-router-dom';

import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

import ModalBoxesContainer from '../../common/feedback/ModalBoxes/ModalBoxesContainer/ModalBoxesContainer';
import useAppInfo from '../../common/hooks/useAppInfo';
import NotificationContainer from '../../common/notifications/NotificationContainer';
import { FileViewerProvider } from '../../contexts/FileViewerContext';
import { FiltersProvider } from '../../contexts/filters/FiltersContext';
import { generateAppTheme } from '../../index';
import PendoAnalytics from '../../pendo/PendoUtils';
import FileViewer from '../FileViewer/FileViewer';
import { NotificationsProvider } from '../Notifications/NotificationsContext';

import HistoryBlock from './Container/HistoryBlock';
import Layout from './Container/Layout/Layout';
import TasksContainer from './Container/Layout/Tasks/TasksContainer';

export default function Root() {
  const appInfo = useAppInfo();

  return (
    <FileViewerProvider>
      <FiltersProvider>
        <HistoryBlock />
        <div className="page-outer-container" style={generateAppTheme(appInfo.displayAppVersion)}>
          <NotificationsProvider>
            <Layout>
              <Outlet />
            </Layout>
          </NotificationsProvider>
        </div>
        <ModalBoxesContainer />
        <NotificationContainer />
        <LoadingOverlay />
        <TasksContainer />
        <FileViewer />
        <PendoAnalytics />
        {/*<NewRelicBrowserMonitoring /> todo: disabled because of conflict with jsPDF library */}
      </FiltersProvider>
    </FileViewerProvider>
  );
}
