import React from 'react';

import Button from '../../../../../common/general/Button';
import { getPatientPreferredName } from '../../../../../services/patient';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import EditPatientPrimaryContact from '../EncounterDescNew/EditPatientPrimaryContact';

export default function NewEncounterPageInfo({
  currentPatient,
  showPrimaryContact,
  onPatientPrimaryContactUpdate,
  showAttemptTracking,
  onAttemptTracking
}) {
  return (
    <PageInfoHeader
      objectRecordLabel={true}
      withLabel={false}
      pageInfo={
        <PageInfoHeader.CollapsibleList>
          <PageInfoHeader.AdditionalInfo tooltip="Patient Name">
            {currentPatient?.name + getPatientPreferredName(currentPatient)}
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Patient ID">{currentPatient?.patientId}</PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo title="Subject ID">
            {currentPatient?.subjectId || 'No Subject ID'}
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Date of Birth">
            {currentPatient?.dob} ({currentPatient.age} years)
          </PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Study">{currentPatient?.studyName}</PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Site">{currentPatient?.siteName}</PageInfoHeader.AdditionalInfo>
          {showPrimaryContact && (
            <PageInfoHeader.AdditionalInfo tooltip="Phone" title="Primary Contact">
              <EditPatientPrimaryContact
                newPageHeader={true}
                contactNumber={currentPatient.contactNumber ? currentPatient.contactNumber : ''}
                neverCallAgain={currentPatient.neverCallAgain}
                voicemailNotAllowed={currentPatient.voicemailNotAllowed}
                patientUniqueIdentifier={currentPatient.uniqueIdentifier}
                onSuccessfulUpdate={onPatientPrimaryContactUpdate}
              />
            </PageInfoHeader.AdditionalInfo>
          )}
          {showAttemptTracking && (
            <PageInfoHeader.AdditionalInfo>
              <Button size={'h28'} onClick={onAttemptTracking}>
                Attempt Tracking
              </Button>
            </PageInfoHeader.AdditionalInfo>
          )}
        </PageInfoHeader.CollapsibleList>
      }
    />
  );
}
