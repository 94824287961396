import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { sortBy } from 'lodash/collection';
import { isEmpty, isEqual } from 'lodash/lang';

import { copyStudyBudget, exportStudyBudget } from '../../../../../actions/finance/budget/copyStudyBudgetAction';
import { FinStudySiteApi } from '../../../../../api';
import Common from '../../../../../common/common';
import Select from '../../../../../common/data-entry/Select';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import useSessionStorage from '../../../../../common/hooks/useSessionStorage';
import { EXISTING_BUDGETS } from '../../../../../constants/sessionStorageConstants';
import { MANAGE_BUDGETS } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { onRequestError } from '../../../../../services/handlers';
import { generateUrlByKey } from '../../../../root/router';
import { ACTIONS_BUTTON } from '../../../finance/NewInvoice/InvoiceTable/TableConstants';
import { DefaultFinanceCell } from '../../../finance/shared/FinanseTableMUI/DefaultFinanceCell';
import { CustomStudyBudgetToolbar } from '../BudgetDetails/CustomStudyBudgetToolbar';

import 'react-table/react-table.css';

export const BudgetList = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [localStorageFilters, setLocalStorageFilters] = useSessionStorage(EXISTING_BUDGETS, {});
  const [pinnedColumns, setPinnedColumns] = useState({
    right: [ACTIONS_BUTTON]
  });

  const [state, setState] = useState({
    studySiteDetailsList: [],
    pcnList: [],
    searchBudget: '',
    filterStudy: null,
    filterPcn: null,
    filterClient: null,
    filterStatus: null,
    statusList: [
      { id: 1, name: 'Draft' },
      { id: 2, name: 'Pending' },
      { id: 3, name: 'Active' },
      { id: 4, name: 'Inactive' }
    ]
  });

  const applyFilters = useCallback(
    (filterStudy, filterPcn, filterClient, filterStatus) => {
      if (
        filterStudy?.name !== '' ||
        filterPcn?.name !== '' ||
        filterStatus?.name !== '' ||
        filterClient?.name !== ''
      ) {
        const filteredData = props.budgetList.filter(row => {
          const study = row.studyName.toLowerCase();
          const pcn = row.pcn?.toLowerCase();
          const status = row.status?.toLowerCase();
          const client = row.clientName.toLowerCase();
          const isStudy = filterStudy?.name?.toLowerCase() ? study === filterStudy.name.toLowerCase() : true;
          const isPcn = filterPcn?.projectCode?.toLowerCase() ? pcn === filterPcn.projectCode.toLowerCase() : true;
          const isStatus = filterStatus?.name?.toLowerCase() ? status === filterStatus.name.toLowerCase() : true;
          const isClient = filterClient?.name?.toLowerCase() ? client === filterClient.name.toLowerCase() : true;
          return isStudy && isPcn && isStatus && isClient;
        });
        setData(prevState => (!isEqual(filteredData, prevState) ? filteredData : prevState));
      } else {
        setData([]);
      }
    },
    [props.budgetList]
  );

  useEffect(() => {
    FinStudySiteApi.allStudiesWithProtocolAssigned().then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        studySiteDetailsList: data ? data.slice().sort(Common.generateSortFuncObjectFieldAlphabetical('name')) : [],
        pcnList: data ? data.slice().sort(Common.generateSortFuncObjectFieldAlphabetical('projectCode')) : []
      }));
    }, onRequestError);
  }, []);

  useEffect(() => {
    if (
      !isEmpty(localStorageFilters) &&
      (localStorageFilters.filterStudy ||
        localStorageFilters.filterPcn ||
        localStorageFilters.filterClient ||
        localStorageFilters.filterStatus)
    ) {
      setState(prevState => ({
        ...prevState,
        filterStudy: localStorageFilters.filterStudy,
        filterPcn: localStorageFilters.filterPcn,
        filterClient: localStorageFilters.filterClient,
        filterStatus: localStorageFilters.filterStatus
      }));
      applyFilters(
        localStorageFilters.filterStudy,
        localStorageFilters.filterPcn,
        localStorageFilters.filterClient,
        localStorageFilters.filterStatus
      );
    }
  }, [applyFilters, localStorageFilters]);

  useEffect(() => {
    if (!isEmpty(state.filterStudy)) {
      const pcn = state.pcnList.filter(item => item?.name === state.filterStudy?.name);
      if (pcn.length === 1) {
        setState(prevState => ({ ...prevState, filterPcn: pcn[0] }));
      }
    } else {
      setState(prevState => ({ ...prevState, filterPcn: null }));
    }
  }, [state.filterStudy, state.pcnList]);

  useEffect(() => {
    if (!isEmpty(state.filterPcn) && isEmpty(state.filterStudy)) {
      setState(prevState => ({ ...prevState, filterStudy: prevState.filterPcn }));
    }
  }, [state.filterPcn, state.filterStudy]);

  useEffect(() => {
    if (!isEmpty(data)) {
      applyFilters(
        localStorageFilters.filterStudy,
        localStorageFilters.filterPcn,
        localStorageFilters.filterClient,
        localStorageFilters.filterStatus
      );
    }
  }, [
    applyFilters,
    data,
    localStorageFilters.filterClient,
    localStorageFilters.filterPcn,
    localStorageFilters.filterStatus,
    localStorageFilters.filterStudy,
    props.budgetList
  ]);

  const onFilterChange = useCallback(
    (newValue, filterName) => {
      if (!isEqual(state[filterName], newValue)) {
        if (filterName === 'filterPcn') {
          setState(prevState => ({
            ...prevState,
            filterPcn: isEmpty(newValue) ? null : newValue,
            filterStudy: isEmpty(newValue) ? null : prevState.filterStudy
          }));
        } else {
          setState(prevState => ({ ...prevState, [filterName]: isEmpty(newValue) ? null : newValue }));
        }
      }
    },
    [state]
  );

  const columns = useMemo(
    () => [
      {
        headerName: 'Study',
        field: 'studyName',
        minWidth: 200,
        flex: 1,
        DefaultFinanceCell
      },
      {
        headerName: 'PCN',
        field: 'pcn',
        minWidth: 150,
        flex: 1,
        renderCell: row => {
          return row.row.pcn ? <span>{row.row.pcn}</span> : '--';
        }
      },
      {
        headerName: 'Client',
        field: 'clientName',
        minWidth: 150,
        flex: 1,
        renderCell: DefaultFinanceCell
      },
      {
        headerName: 'Budget Version',
        field: 'version',
        width: 150,
        flex: 1,
        renderCell: row => {
          return row.row.version ? <span>{row.row.version}</span> : '--';
        }
      },
      {
        headerName: 'Description',
        field: 'description',
        minWidth: 200,
        flex: 1,
        renderCell: row => {
          const url = generateUrlByKey('Study Budget.Budget Details', { id: row.id });
          const name = row.row.description ? row.row.description : '--';
          return (
            <NavLink to={url} title={name} className="MuiDataGrid-cellContent">
              {name}
            </NavLink>
          );
        }
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 150,
        flex: 1,
        renderCell: row => {
          const { status } = row.row;

          return status ? status.charAt(0).toUpperCase() + status.slice(1).toLowerCase() : '--';
        }
      },
      {
        headerName: 'Comments',
        field: 'comment',
        minWidth: 200,
        flex: 1,
        renderCell: row => {
          const value = row.row.comment;
          return (
            <div className="MuiDataGrid-cellContent" title={value}>
              {value}
            </div>
          );
        }
      },
      {
        headerName: 'Actions',
        field: 'actions',
        type: 'actions',
        resizable: false,
        headerAlign: 'left',
        width: 155,
        renderCell: row => {
          return (
            <ButtonGroup>
              {userHasAccessTo(MANAGE_BUDGETS) && (
                <Button size="h28" priority="high" onClick={() => dispatch(copyStudyBudget(row.id))}>
                  Copy
                </Button>
              )}
              <Button size="h28" priority="medium" onClick={() => dispatch(exportStudyBudget(row.id))}>
                Export
              </Button>
            </ButtonGroup>
          );
        }
      }
    ],
    [dispatch]
  );

  const pcnList = useMemo(() => {
    const existedPCN = state.pcnList.filter(item => item.projectCode);
    return sortBy(
      state.filterStudy ? existedPCN.filter(item => item?.name === state.filterStudy?.name) : existedPCN,
      'projectCode'
    );
  }, [state.filterStudy, state.pcnList]);

  const handlePinnedColumnsChange = useCallback(updatedPinnedColumns => {
    setPinnedColumns({
      ...updatedPinnedColumns,
      right: [...updatedPinnedColumns.right.filter(column => column !== ACTIONS_BUTTON), ACTIONS_BUTTON]
    });
  }, []);

  const getTogglableColumns = useCallback(
    columns => columns.filter(column => ![ACTIONS_BUTTON].includes(column.field)).map(column => column.field),
    []
  );

  return (
    <section className="existing-budgets">
      <h5>Existing Budgets</h5>
      <div className="general-header-group-container general-header-wrapper">
        <Select
          label="Study"
          dataSource={state.studySiteDetailsList}
          value={state.filterStudy}
          onChange={newValue => onFilterChange(newValue, 'filterStudy')}
          clearable
          searchable
          data-testid="budget-study-filter"
        />
        <Select
          label="PCN"
          dataSource={pcnList}
          value={state.filterPcn}
          onChange={newValue => onFilterChange(newValue, 'filterPcn')}
          optionLabelKey="projectCode"
          clearable
          searchable
          data-testid="budget-pcn-filter"
          customSelectedValueTemplateFunction={studies =>
            studies.map(study => (study.projectCode ? study.projectCode : 'No PCN'))
          }
        />
        <Select
          label="Client"
          dataSource={
            props.clientList
              ? props.clientList.slice().sort(Common.generateSortFuncObjectFieldAlphabetical('name'))
              : []
          }
          value={state.filterClient}
          onChange={newValue => onFilterChange(newValue, 'filterClient')}
          clearable
          searchable
          data-testid="budget-client-filter"
        />
        <Select
          label="Status"
          dataSource={state.statusList}
          value={state.filterStatus}
          onChange={newValue => onFilterChange(newValue, 'filterStatus')}
          clearable
          searchable
          data-testid="budget-status-filter"
        />
        <Button
          size="h56"
          onClick={() => {
            applyFilters(state.filterStudy, state.filterPcn, state.filterClient, state.filterStatus);
            setLocalStorageFilters({
              ...localStorageFilters,
              filterStudy: state.filterStudy,
              filterPcn: state.filterPcn,
              filterStatus: state.filterStatus,
              filterClient: state.filterClient
            });
          }}
        >
          Apply
        </Button>
      </div>
      <DataGridPremium
        data-testid="study-budget-table"
        getRowId={row => row?.id}
        rows={data || []}
        columns={columns}
        hideFooter
        className="study-budget-table"
        rowHeight={38}
        slotProps={{
          columnsPanel: {
            getTogglableColumns
          }
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'studyName', sort: 'asc' }]
          }
        }}
        localeText={{ noRowsLabel: 'No Record Found' }}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        disableRowSelectionOnClick
        slots={{ toolbar: CustomStudyBudgetToolbar }}
        disableRowGrouping
        disableAggregation
      />
    </section>
  );
};

export default BudgetList;
