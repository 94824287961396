import React, { useEffect, useRef, useState } from 'react';

import Input from '../../../../../../../../common/data-entry/Input';
import useOutsideClickDetector from '../../../../../../../../common/hooks/useOutsideClickDetector';

import { SSN_RULE_MESSAGE, SSN_VALIDATION_MESSAGE } from './constants';
import { isValidSsn } from './financeDetailsService';

import './FinanceDetails.scss';

export default function FinanceDetails({
  handleInputChange,
  isRequiredField,
  getValidationMessage,
  info,
  userCanManageFinanceDetails
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);

  const { ssn, cardId, address } = info;
  const isUS = address.country === 'US';

  const dialogRef = useRef();
  useOutsideClickDetector(dialogRef, () => {
    setShowDialog(false);
  });

  useEffect(() => {
    if (ssn && isUS && !isValidSsn(ssn)) {
      setShowDialog(true);
      setShowValidationMessage(true);
    } else {
      setShowDialog(false);
      setShowValidationMessage(false);
    }
  }, [ssn, isUS]);

  return (
    <div className="row">
      <div className="col-12 apm-form-label">Finance Details</div>
      <div className="col-4">
        <Input
          label={isUS ? 'SSN' : 'SIN'}
          name="ssn"
          value={ssn}
          onChange={handleInputChange}
          required={isRequiredField('ssn')}
          validationMessage={showValidationMessage ? SSN_VALIDATION_MESSAGE : getValidationMessage('ssn')}
          disabled={!userCanManageFinanceDetails}
        />
        {showDialog && (
          <div ref={dialogRef} className={'dropdown-menu-wrapper'}>
            <div className={'message'}>{SSN_RULE_MESSAGE}</div>
          </div>
        )}
      </div>
      <div className="col-4">
        <Input
          disabled={!isUS || !userCanManageFinanceDetails}
          label="PID"
          name="cardId"
          value={cardId}
          onChange={handleInputChange}
          validationMessage={getValidationMessage('cardId')}
        />
      </div>
    </div>
  );
}
