export const screeningStatuses = [
  {
    name: 'Identified',
    code: 'IDENTIFIED',
    id: 'IDENTIFIED',
    isDropStatus: false
  },
  {
    name: 'Pre-screening',
    code: 'PRE_SCREENING',
    id: 'PRE-SCREENING',
    isDropStatus: false
  },
  {
    name: 'Pre-screened',
    code: 'PRE_SCREENED',
    id: 'PRE-SCREENED',
    isDropStatus: false
  },
  {
    name: 'Pre-screen Failed',
    code: 'PRE_SCREEN_FAILED',
    id: 'PRE-SCREEN-FAILED',
    isDropStatus: true
  },
  {
    name: 'Canceled',
    code: 'CANCELED',
    id: 'CANCELED',
    isDropStatus: true
  }
];
