import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';

import NotificationManager from '../../../common/notifications/NotificationManager';
import { RESET_PASSWORD_FOR } from '../../../constants/notificationMessages';
import { scPurple80 } from '../../../constants/systemColors';
import backgroundImage from '../../../images/login-background-custom.jpg';
import { CustomForgotPassword } from '../../root/login/CustomForgotPassword/CustomForgotPassword';

// Made to look like login page reset password
const CustomTheme = {
  container: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center'
  },
  formContainer: {
    marginTop: '5%',
    marginBottom: '0'
  },
  button: {
    backgroundColor: scPurple80
  }
};

// This component only loads when user is logged in and
// route is like '/reset-password?code=123456&username=e@mail.com'
const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    const urlParameters = location?.search || '';
    // get Cognito code and Username from url
    const { code, username } = parse(urlParameters);
    if (!!code && username) {
      setCode(code.toString());
      setUsername(username.toString());
    }
  }, [location]);

  const onSuccess = username => {
    NotificationManager.success(`${RESET_PASSWORD_FOR} ${username}`);
    navigate('/');
  };

  // Only render reset form if code & username are present in url
  const isVisible = !!code && !!username;

  // Render the same CustomForgotPassword component we use when not logged in
  return (
    <Fragment>
      {isVisible && (
        <div style={CustomTheme.container}>
          <CustomForgotPassword
            authData={{ username, code }}
            theme={CustomTheme}
            authState={'forgotPassword'}
            successCallback={onSuccess}
          />
        </div>
      )}
    </Fragment>
  );
};

const component = ResetPassword;

export const resetPasswordRouterConfig = {
  name: 'Reset Password',
  path: '/reset-password',
  component
};
