import axios from 'axios';

import separateAxiosInstance from '../separateAxiosInstance';

const ROOT = 'api/v1/users';

const UserApiApi = {
  getListOfActiveUsers(sitePatientIdentifier = '', patientItemGroupId = '', logRowId = '') {
    let point = `${ROOT}/personnels?sitePatientIdentifier=${sitePatientIdentifier}&patientItemGroupId=${patientItemGroupId}&logRowId=${logRowId}&whodiditFlag=1`;
    if (sitePatientIdentifier) {
      point = `${point}`;
    }
    return axios.get(point);
  },

  getListOfStudyManagers(sitePatientIdentifier = '') {
    let point = `${ROOT}/personnels/SM`;
    if (sitePatientIdentifier) {
      point = `${point}?sitePatientIdentifier=${sitePatientIdentifier}&whodiditFlag=1`;
    }
    return axios.get(point);
  },

  getListOfAuthorities() {
    return axios.get(`${ROOT}/authorities/`);
  },

  getUserInfo() {
    return axios.get(`${ROOT}/info/user`);
  },

  getUserPreferences() {
    return axios.get(`${ROOT}/info/me/preferences`, { hideLoader: true });
  },

  getGlobalEnvironmentSettings() {
    return axios.get(`api/v1/global-environment-settings/`, { hideLoader: true });
  },

  changeGlobalEnvironmentSettings(settings) {
    return axios.put(`api/v1/global-environment-settings/`, settings, { hideLoader: true });
  },

  updateUserPreferences(preferences) {
    return axios.put(`${ROOT}/info/me/preferences`, preferences, { hideLoader: true });
  },

  checkFirstTimeLogin() {
    return axios.get(`${ROOT}/firstTimeLogin`);
  },

  updatePasswordExpire(data) {
    return separateAxiosInstance.post(`${ROOT}/passwordLambda`, data);
  },

  writeLogInTime() {
    return axios.get(`${ROOT}/login`);
  },

  writeLogoutTime(id) {
    return axios.post(`${ROOT}/logout`, id, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
  },
  getAssigneesBySsu(ssuId) {
    return axios.get(`${ROOT}/study-sites/${ssuId}/personnel`, {
      hideLoader: true
    });
  },
  getAllEligibleAssignees() {
    return axios.get(`${ROOT}/eligible-assignees`);
  },
  getUserSsuRelatedRoles(ssuPatientId) {
    return axios.get(`${ROOT}/site-patients/${ssuPatientId}/roles`);
  },
  getPersonnelBySsu(ssuIds) {
    return axios.post(
      `${ROOT}/study-sites/personnel`,
      { ssuIds },
      {
        hideLoader: true
      }
    );
  }
};

export default UserApiApi;
