import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTable from 'react-table';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import { StudyApi } from '../../../../../../../api';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { ADDED } from '../../../../../../../constants/notificationMessages';
import { CLOSED } from '../../../../../../../constants/ssuStatuses';
import {
  ADD_PATIENT_TO_STUDY,
  MANAGE_MRN,
  MANAGE_SSU_PATIENT_INFORMATION,
  TRANSFER_PATIENTS
} from '../../../../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../../constants/userRoles';
import { pendoTrackDefaultSortingChange } from '../../../../../../../pendo/PendoUtils';
import { userHasAccessTo, userHasRole } from '../../../../../../../services/auth';
import { onRequestError } from '../../../../../../../services/handlers';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';
import { PatientInfoContext } from '../../PatientInfoContext';

import AddNewStudyModal from './AddNewStudyModal/AddNewStudyModal';
import MedicalRecordNumberColumn from './MedicalRecordNumberColumn/MedicalRecordNumberColumn';
import { PatientSourceColumn } from './PatientSourceColumn/PatientSourceColumn';
import SubjectIdColumn from './SubjectIdColumn/SubjectIdColumn';
import TransferPatientModal from './TransferPatientModal/TransferPatientModal';
import { calculateTextWidth } from './TextWidthProvider';

import './StudyHistory.scss';

const defaultMarginBetweenColumns = 40;

export default function PatientInfoMainSectionStudyHistory() {
  const dropStatuses = [
    'Pre-screen Failed',
    'Screen Failed',
    'Run In Failed',
    'Withdrawn',
    'Canceled',
    'Early Termination'
  ];
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const { updatePatientRelatedStudies, studyHistory, sourcesList } = useContext(PatientInfoContext);
  const patientId = currentRoute.params?.patientId;
  const columns = [
    {
      Header: 'Study',
      accessor: 'studyName',
      maxWidth: getColumnWidth(studyHistory, 'studyName', 'Study') + defaultMarginBetweenColumns,
      Cell({ value }) {
        return <span title={value}>{value}</span>;
      }
    },
    {
      Header: 'Site',
      accessor: 'siteName',
      maxWidth: getColumnWidth(studyHistory, 'siteName', 'Site') + defaultMarginBetweenColumns,
      Cell({ value }) {
        return <span title={value}>{value}</span>;
      }
    },
    {
      Header: 'Subject ID',
      accessor: 'patientSubjectId',
      width: 150,
      Cell({ original }) {
        return (
          <SubjectIdColumn
            isAllowedToManage={isAllowedToManage(original.studySiteStatus)}
            granted={original.granted}
            patientSubjectId={original.patientSubjectId}
            ssuPatientId={original.ssuPatientId}
            key={original.ssuPatientId}
          />
        );
      }
    },
    {
      id: 'startDate',
      Header: 'Start',
      className: 'study-history-start-date',
      headerClassName: 'study-history-start-date',
      accessor({ startDate }) {
        return startDate ? +moment(startDate) : null;
      },
      Cell({ original }) {
        return original.startDate && moment(original.startDate).format('DD/MMM/YYYY');
      },
      width: 95
    },
    {
      id: 'dateDash',
      Header: '',
      className: 'study-history-date-dash',
      headerClassName: 'study-history-date-dash',
      accessor({ startDate }) {
        return startDate ? +moment(startDate) : null;
      },
      Cell({ original }) {
        return original.endDate && original.startDate && ` - `;
      },
      width: 10,
      sortable: false
    },
    {
      id: 'completionDate',
      Header: 'Complete',
      className: 'study-history-completion-date',
      headerClassName: 'study-history-completion-date',
      accessor({ endDate }) {
        return endDate ? +moment(endDate) : null;
      },
      Cell({ original }) {
        return original.endDate && moment(original.endDate).format('DD/MMM/YYYY');
      },
      width: 135
    },
    {
      Header: 'Status',
      accessor: 'patientStatus',
      maxWidth: getColumnWidth(studyHistory, 'patientStatus', 'Status') + defaultMarginBetweenColumns,
      Cell({ value }) {
        return <span title={value}>{value}</span>;
      }
    },

    {
      Header: 'Source',
      accessor: 'patientSource.source',
      className: 'study-history-source-field',
      maxWidth: getColumnWidth(studyHistory, 'patientSource', 'Source') + defaultMarginBetweenColumns,
      width: 265,
      Cell({ original }) {
        return (
          <PatientSourceColumn
            key={original.ssuPatientId}
            ssuPatientId={original.ssuPatientId}
            patientSource={original.patientSource}
            isAllowedToManage={isAllowedToManage(original.studySiteStatus)}
          />
        );
      }
    },

    {
      Header: 'MRN',
      accessor: 'medicalRecordNumber',
      width: 150,
      Cell({ original }) {
        return (
          <MedicalRecordNumberColumn
            isAllowedToManage={isAllowedToManage(original.studySiteStatus)}
            patientMedicalRecordNumber={original.medicalRecordNumber}
            ssuPatientId={original.ssuPatientId}
            granted={original.granted}
            key={original.ssuPatientId}
          />
        );
      }
    }
  ];

  if (userHasAccessTo(TRANSFER_PATIENTS)) {
    columns.push({
      id: 'transferPatient',
      accessor(study) {
        return study;
      },
      width: 150,
      Cell({ value }) {
        return (
          value.ssuPatientLocked && (
            <Button onClick={openTransferringModal} title="Transfer Patient" size="h28" priority="medium">
              Transfer
            </Button>
          )
        );

        function openTransferringModal() {
          return ModalBoxes.open({
            component: <TransferPatientModal study={value} />,
            onClose(update) {
              if (update !== true) return;
              updatePatientRelatedStudies();
            }
          });
        }
      }
    });
  }

  const dataWithExtendedPatientSource = useMemo(
    function() {
      if (isEmpty(sourcesList)) return [];
      return studyHistory.map(study => ({
        ...study,
        patientSource: sourcesList.find(({ id }) => id === study.patientSourceId)
      }));
    },
    [sourcesList, studyHistory]
  );

  return (
    <section className="ims-study-history">
      <header>
        <h3 className="mr-2">Studies</h3>
        {userHasAccessTo(ADD_PATIENT_TO_STUDY) && (
          <Button size="h28" priority="medium" onClick={openNewStudyModal} title="Add Study">
            <Icon>add</Icon>
          </Button>
        )}
      </header>
      <ReactTable
        data={dataWithExtendedPatientSource || []}
        columns={columns}
        minRows={1}
        multiSort
        onSortedChange={pendoTrackDefaultSortingChange}
        className="study-history-table"
        showPagination={false}
        resizable={false}
      />
    </section>
  );

  function onSave(data) {
    const { studyIdentifier, patientSourceId, patientStatusName, studySiteIdentifier } = data;
    const obj = {
      patientUniqueIdentifier: patientId,
      studyIdentifier: studyIdentifier,
      studySiteIdentifier: studySiteIdentifier,
      patientSourceId: patientSourceId,
      statusName: patientStatusName,
      statusChangeLocation: 'PATIENT_INFO'
    };
    StudyApi.addPatientStudy(obj)
      .then(res => {
        NotificationManager.success(ADDED);
        ModalBoxes.closeAll();
        navigate(
          generateUrlByKey(currentRoute.key, {
            ...currentRoute.params,
            ssuPatientId: res.data.sitePatientId
          }),
          { replace: true }
        );
        updatePatientRelatedStudies();
      })
      .catch(onRequestError);
  }

  function openNewStudyModal() {
    if (patientId) {
      const studiesToReject = studyHistory
        .filter(({ patientStatus }) => {
          return !dropStatuses.includes(patientStatus) && patientStatus !== 'Completed';
        })
        .map(({ studyId }) => studyId);
      ModalBoxes.open({
        component: <AddNewStudyModal studiesToReject={studiesToReject} onSave={onSave} />
      });
    }
  }
}

function isAllowedToManage(studySiteStatus) {
  return (
    userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
    (studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_SSU_PATIENT_INFORMATION, MANAGE_MRN))
  );
}

const getColumnWidth = (rows, accessor, headerText) => {
  const textStyle = { fontSize: '14px', fontWeight: 400 };
  return Math.max(
    ...rows.map(row => calculateTextWidth(row[accessor] || '', textStyle)),
    calculateTextWidth(headerText || '', textStyle)
  );
};
